import { useState, useEffect, useContext } from "react";
import Navigation from "../Navigation/Navigation";
import YLoader from "../components/Loader";
import { Grid, Typography, Button } from '@mui/material';
import { useToasts } from "react-toast-notifications";
import "./verification.css";
import userVerification from "../assets/images/userverification.png"
import IdentityVerificationDialog from "./IdentityVerification/IdentityVerificationDialog";
import UpdateIdentityVerificationDialog from "./IdentityVerification/UpdateIdentityVerificationDialog";
import ProfessionVerificationDialog from "./ProfessionVerification/ProfessionVerificationDialog";
import UpdateProfessionVerificationDialog from "./ProfessionVerification/UpdateProfessionVerificationDialog";
import useAPIRequest from "../API/useApiRequest";
import { YContext } from "../Context/YContext";
import { useTheme } from '@mui/material/styles';

const Verification = () => {
    const [loading, setLoading] = useState(false);
    const [getUploadedDocument, setGetUploadedDocument] = useState([]);
    const { addToast } = useToasts();
    const theme = useTheme();

    // Services
    const { apiRequests } = useAPIRequest();
    const { currentUser } = useContext(YContext);

    // Dialogs
    const [openIdentityVerificationDialog, setOpenIdentityVerificationDialog] = useState(false);
    const [openUpdateIdentityVerificationDialog, setOpenUpdateIdentityVerificationDialog] = useState(false);
    const [openProfessionVerificationDialog, setOpenProfessionVerificationDialog] = useState(false);
    const [openUpdateProfessionVerificationDialog, setOpenUpdateProfessionVerificationDialog] = useState(false);

    const handleIdentityVerificationDialogClickOpen = () => {
        setOpenIdentityVerificationDialog(true);
    };

    const handleIdentityVerificationDialogClickClose = () => {
        setOpenIdentityVerificationDialog(false);
    };

    const handleUpdateIdentityVerificationDialogClickOpen = () => {
        setOpenUpdateIdentityVerificationDialog(true);
    };

    const handleUpdateIdentityVerificationDialogClickClose = () => {
        setOpenUpdateIdentityVerificationDialog(false);
    };

    const handleProfessionVerificationDialogClickOpen = () => {
        setOpenProfessionVerificationDialog(true);
    };

    const handleProfessionVerificationDialogClickClose = () => {
        setOpenProfessionVerificationDialog(false);
    };

    const handleUpdateProfessionVerificationDialogClickOpen = () => {
        setOpenUpdateProfessionVerificationDialog(true);
    };

    const handleUpdateProfessionVerificationDialogClickClose = () => {
        setOpenUpdateProfessionVerificationDialog(false);
    };

    useEffect(() => {
        getVerificationStatus();
    }, []);

    const getVerificationStatus = async () => {
        setLoading(true)
        const { response, data, error } = await apiRequests({
            endPoint: `identities/findActiveByUser`,
            method: "GET",
            addToast,
        });

        if (data) {
            setGetUploadedDocument(data);
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }
    };

    // Filter and sort to get the latest identity document

    const identityUploadedDocuments = getUploadedDocument.filter(option => option.documentType === "ID_CARD_DOCUMENT_FRONT" || option.documentType === "ID_CARD_DOCUMENT_BACK");

    const sortedIdentityDocuments = identityUploadedDocuments.sort((a, b) => new Date(b.created) - new Date(a.created));

    // Filter and sort to get the latest verification document

    const professionUploadedDocuments = getUploadedDocument.filter(option => option.documentType !== "ID_CARD_DOCUMENT_FRONT" && option.documentType !== "ID_CARD_DOCUMENT_BACK");

    const sortedProfessionDocuments = professionUploadedDocuments.sort((a, b) => new Date(b.created) - new Date(a.created));

    const latestProfessionDocument = sortedProfessionDocuments[0];

    let identityFrontDocument = sortedIdentityDocuments.find(doc => doc.documentType === "ID_CARD_DOCUMENT_FRONT") || null;
    let identityBackDocument = sortedIdentityDocuments.find(doc => doc.documentType === "ID_CARD_DOCUMENT_BACK") || null;
    let professionalDocuments = latestProfessionDocument || null;

    let statusMessage;

    if (identityFrontDocument === null || professionalDocuments === null) {
        statusMessage = <span style={{ color: 'red' }}>NOT VERIFIED</span>;
    } else if (
        identityFrontDocument.verificationStatus === 'PENDING' && professionalDocuments.verificationStatus === 'PENDING'
    ) {
        statusMessage = <span style={{ color: theme.palette.mode === "light" ? "#002E6E" : "yellow" }}>PENDING FOR VERIFICATION</span>;
    } else if (
        identityFrontDocument.verificationStatus === 'VERIFICATION_FAILED' ||
        professionalDocuments.verificationStatus === 'VERIFICATION_FAILED'
    ) {
        statusMessage = <span style={{ color: 'red' }}>VERIFICATION FAILED</span>;
    } else if (
        identityFrontDocument.verificationStatus === 'VERIFIED' && professionalDocuments.verificationStatus === 'VERIFIED'
    ) {
        statusMessage = <span style={{ color: 'green' }}>VERIFIED</span>;
    } else if (
        identityFrontDocument.verificationStatus === 'VERIFIED' || professionalDocuments.verificationStatus === 'VERIFIED'
    ) {
        statusMessage = <span style={{ color: theme.palette.mode === "light" ? "#002E6E" : "yellow" }}>PARTIALLY VERIFIED</span>;
    } else {
        statusMessage = <span>Invalid state</span>;
    }

    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="verification__Drawer">
                        <Navigation />
                    </div>

                    <div className="verification-container">

                        <Grid container direction="row" rowSpacing={1} style={{ marginTop: "5px" }}>

                            <Grid item xs={12}>
                                <Typography variant="h5" color="text.primary" align="left">
                                    Verify Your Account
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" color="text.primary">
                                    At MeddyConnect, we have zero fake account policy
                                    and we take verification very seriously.<br />
                                    Your account may get suspended in the event of
                                    failure of verification.
                                </Typography>
                            </Grid>

                        </Grid>

                        <div className="verification-status-container">
                            <div className="verification-status-title">
                                <Typography variant="h6" align="left" color="text.primary">
                                    Status :
                                </Typography>
                            </div>
                            <div className="verification-status">
                                <Typography variant="h6" align="left" color="text.primary">
                                    {statusMessage}
                                </Typography>
                            </div>
                        </div>

                        <Grid container direction="row" justifyContent="center">
                            <Grid item xs={12} sm={6}>
                                <div className="verify-img-container">
                                    <img src={userVerification} alt="verify image" className="verify-img" />
                                </div>
                            </Grid>
                        </Grid>

                        <div className="verify-btn-container">
                            <Button variant="contained" fullWidth className='verify-button' color="primary"
                                onClick={() => {
                                    if (sortedIdentityDocuments.length === 0) {
                                        handleIdentityVerificationDialogClickOpen();
                                    } else {
                                        handleUpdateIdentityVerificationDialogClickOpen();
                                    }
                                }}
                            >
                                Identity Verification
                            </Button>

                            <Button variant="contained" fullWidth className='verify-button' color="primary"
                                onClick={() => {
                                    if (latestProfessionDocument === undefined) {
                                        handleProfessionVerificationDialogClickOpen();
                                    } else {
                                        handleUpdateProfessionVerificationDialogClickOpen();
                                    }
                                }}
                            >
                                Profession Verification
                            </Button>
                        </div>

                        <div>
                            <IdentityVerificationDialog
                                openIdentityVerificationDialog={openIdentityVerificationDialog}
                                handleIdentityVerificationDialogClickClose={handleIdentityVerificationDialogClickClose}
                                getVerificationStatus={getVerificationStatus}
                            />
                        </div>

                        <div>
                            <UpdateIdentityVerificationDialog
                                openUpdateIdentityVerificationDialog={openUpdateIdentityVerificationDialog}
                                handleUpdateIdentityVerificationDialogClickClose={handleUpdateIdentityVerificationDialogClickClose}
                                sortedIdentityDocuments={sortedIdentityDocuments}
                                getVerificationStatus={getVerificationStatus}
                            />
                        </div>

                        <div>
                            <ProfessionVerificationDialog
                                openProfessionVerificationDialog={openProfessionVerificationDialog}
                                handleProfessionVerificationDialogClickClose={handleProfessionVerificationDialogClickClose}
                                getVerificationStatus={getVerificationStatus}
                            />
                        </div>

                        <div>
                            <UpdateProfessionVerificationDialog
                                openUpdateProfessionVerificationDialog={openUpdateProfessionVerificationDialog}
                                handleUpdateProfessionVerificationDialogClickClose={handleUpdateProfessionVerificationDialogClickClose}
                                latestProfessionDocument={latestProfessionDocument}
                                getVerificationStatus={getVerificationStatus}
                            />
                        </div>

                    </div>
                </div>

                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default Verification
