import React, { useState, useEffect, useContext } from 'react';
import {
    Grid, Typography, Dialog, DialogTitle,
    Button, DialogContent, DialogActions,
    Paper, Chip, Slide, IconButton
} from '@mui/material';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { useToasts } from "react-toast-notifications";
import './newproductcards.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import VerifiedIcon from '@mui/icons-material/Verified';
import PhoneIcon from '@mui/icons-material/Phone';
import ImagesCarousel from '../Components/ImagesCarousel';
import useAPIRequest from "../../API/useApiRequest";
import ImageEnlargeDialog from '../Components/ImageEnlargeDialog';
import { BsChat } from "react-icons/bs";
import { YContext } from "../../Context/YContext";
import moment from "moment";
import Constants from "../../MeddyConnectConstant";
import { useHistory } from "react-router-dom";
import UrlRoute from "../../API/UrlRoute";
import { FaRegFilePdf } from "react-icons/fa";
import PDFViewer from "../../components/PDFViewer";
import CloseIcon from '@mui/icons-material/Close';
import ReadMore from "../../components/ReadMore";
import { useParams } from 'react-router-dom';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const NewProductLaunchDetails = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const { addToast } = useToasts();
    const { newProductLaunchId } = useParams();

    const [singleProductData, setSingleProductData] = useState(null);
    const [sellerContactNumber, setSellerContactNumber] = useState("");
    const [sellerName, setSellerName] = useState("");
    const [chipData, setChipData] = React.useState([]);
    const [productImages, setProductImages] = useState([]);
    const [clickedImage, setClickedImage] = useState('');
    const [selectedPDF, setSelectedPDF] = useState({ contentType: null, content: null });
    const [uploadedAttachment, setUploadedAttachment] = useState([]);

    // Services
    const { apiRequests } = useAPIRequest();
    const { currentUser } = useContext(YContext);

    // Dialogs
    const [enlargeImgDialog, setEnlargeImgDialog] = useState(false);
    const [openAlertVerificationDialog, setOpenAlertVerificationDialog] = React.useState(false);
    const [openShowSellerContactDetailsDialog, setOpenShowSellerContactDetailsDialog] = useState(false);
    const [openDocumentDialog, setOpenDocumentDialog] = useState(false);

    const handleAlertVerificationDialogClickOpen = () => {
        setOpenAlertVerificationDialog(true);
    };

    const handleAlertVerificationDialogClickClose = () => {
        setOpenAlertVerificationDialog(false);
    };

    const handleShowSellerContactDetailsDialogClickOpen = () => {
        setOpenShowSellerContactDetailsDialog(true);
    };

    const handleShowSellerContactDetailsDialogClickClose = () => {
        setOpenShowSellerContactDetailsDialog(false);
    };

    const handleVerifyClick = () => {
        history.push(Constants.VERIFICATION);
    };

    const handleEnlargeImgDialogClickOpen = (clickImg) => {
        setEnlargeImgDialog(true);
        setClickedImage(clickImg);
    };

    const handleEnlargeImgDialogClickClose = () => {
        setEnlargeImgDialog(false);
    };

    const handleDocumentDialogClickOpen = async (pdfUrl) => {
        try {
            const response = await fetch(pdfUrl);
            const blob = await response.blob();
            const base64String = await blobToBase64(blob);
            setSelectedPDF({ contentType: blob.type, content: base64String });
            setOpenDocumentDialog(true);
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };

    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleDocumentDialogClickClose = () => {
        setOpenDocumentDialog(false);
        setSelectedPDF({ contentType: null, content: null });
    };

    useEffect(() => {
        getBuyNewSingleProduct(newProductLaunchId);
    }, []);

    const getBuyNewSingleProduct = async (newProductLaunchId) => {
        setLoading(true)
        const { response, data, error } = await apiRequests({
            endPoint: `items/${newProductLaunchId}`,
            method: "GET",
            addToast,
        });

        if (data) {
            setSingleProductData(data);
            setSellerName(data.sellerName);
            setChipData(data.itemSuitabilities);
            setProductImages(data.itemImages);
            setUploadedAttachment(data.itemAttachments);
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    const handleImInterested = async (itemId) => {
        setLoading(true)
        const { response, data, error } = await apiRequests({
            endPoint: "items/interest",
            method: "POST",
            body: {
                itemId: itemId,
            },
            addToast,
        });

        if (response.status === 200) {
            setLoading(false);
            addToast("Thank you for showing interest in our product !", { appearance: "success" });
        } else if (response.status === 409) {
            setLoading(false);
            addToast("You have already shown interest in this product !", { appearance: "success" });
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    }

    const getSellerContactDetails = async (newProductLaunchId) => {
        setLoading(true)
        const { response, data, error } = await apiRequests({
            endPoint: `${UrlRoute.GET_SELLER_CONTACT_DETAILS_URL}/${newProductLaunchId}`,
            method: "GET",
            addToast,
        });

        if (data) {
            setSellerContactNumber(data.response);
            setLoading(false);
            handleShowSellerContactDetailsDialogClickOpen();
        } else if (response.status === 403) {
            setLoading(false);
            handleAlertVerificationDialogClickOpen();
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="newproductcards--Drawer">
                        <Navigation />
                    </div>

                    <div className="newproductcards-container">
                        {loading ? (
                            <YLoader loading={loading} />
                        ) : singleProductData ? (
                            <Paper elevation={2} className="newproduct-paper-container">
                                <Grid container direction="row">
                                    <Grid item xs={12}>
                                        <ImagesCarousel productImages={productImages} handleEnlargeImgDialogClickOpen={handleEnlargeImgDialogClickOpen} />
                                    </Grid>
                                </Grid>

                                <div className="newproductlaunch_info">
                                    <Typography variant="h5" align="left" color="text.primary" className="text-capital title" gutterBottom>
                                        {singleProductData.title}
                                    </Typography>

                                    <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital">
                                        <span className='bold-text'>Seller :</span> {singleProductData.sellerName} {singleProductData.sellerVerified ? <Chip icon={<VerifiedIcon color="primary" />} label="Verified" variant="outlined" size="small" /> : ""}
                                    </Typography>

                                    <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital">
                                        <span className='bold-text'> Product :</span> {singleProductData.product}
                                    </Typography>

                                    <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital">
                                        <span className='bold-text'>Brand :</span> {singleProductData.brand}
                                    </Typography>

                                    <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital">
                                        <span className='bold-text'>Posting Date :</span> {singleProductData.created ? moment(singleProductData.created).format("D-MMM-YYYY") : "N/A"}
                                    </Typography>

                                    {singleProductData.productLink?.href && (
                                        <Typography variant="subtitle1" align="left" className="text-capital">
                                            <span className='bold-text'>Product Link :</span>
                                            <a
                                                href={singleProductData.productLink.href}
                                                target="_blank" rel="noreferrer"
                                                style={{ textDecoration: "none" }}
                                            > Visit Site
                                            </a>
                                        </Typography>
                                    )}
                                </div>

                                <Grid container direction="row" style={{ marginTop: "14px" }}>
                                    <Grid item>
                                        <Typography variant="subtitle1" align="left" color="text.primary" className='bold-text'>
                                            Product is useful for :
                                        </Typography>
                                    </Grid>
                                    {chipData.length > 0 ?
                                        <Grid item xs={12}>
                                            <Paper elevation={0}
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    listStyle: 'none',
                                                    backgroundColor: 'transparent',
                                                    p: 0.5,
                                                    m: 0,
                                                }}
                                                component="ul"
                                            >
                                                {chipData.map((data) => {

                                                    return (
                                                        <ListItem key={data.id}>
                                                            <Chip variant="outlined"
                                                                label={data.suitableFor}
                                                                style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff", textTransform: "capitalize" }}
                                                            />
                                                        </ListItem>
                                                    );

                                                })
                                                }
                                            </Paper>
                                        </Grid>
                                        :
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2" color="text.primary">
                                                N/A
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>


                                <Grid container direction="column" style={{ marginTop: "14px" }}>
                                    <Grid item>
                                        <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital bold-text" >
                                            description :
                                        </Typography>
                                    </Grid>
                                    {singleProductData.description ?
                                        <Grid item>
                                            {singleProductData.description.length < 300 ?
                                                <Typography variant="subtitle2" component="span" color="text.primary">
                                                    {singleProductData.description}
                                                </Typography>
                                                :
                                                <Typography variant="subtitle2" component="span" color="text.primary">
                                                    <ReadMore>
                                                        {singleProductData.description}
                                                    </ReadMore>
                                                </Typography>
                                            }
                                        </Grid> : "No description"}
                                </Grid>

                                {uploadedAttachment && uploadedAttachment.length > 0 ?
                                    <Grid container direction="column" style={{ marginTop: "14px" }}>
                                        <Grid item>
                                            <Typography variant="subtitle1" color="text.primary" align="left" className="text-capital bold-text" >
                                                uploaded attachment :
                                            </Typography>
                                        </Grid>
                                    </Grid> : ""}

                                {uploadedAttachment && (
                                    <div className="pdf-container">
                                        {uploadedAttachment.map((element, index) => {
                                            return (
                                                <div key={element.id} style={{ position: "relative" }}>
                                                    <FaRegFilePdf style={{ color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }} className='dialog-uploaded-icon' onClick={() => handleDocumentDialogClickOpen(element.attachmentLink.href)} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}

                                <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center" style={{ padding: "10px 0px" }}>
                                    {/* <Grid item xs={6}>
                                    <Button variant="contained" startIcon={<BsChat />} className="newproduct-btn" fullWidth color="primary">
                                        chat
                                    </Button>
                                </Grid> */}
                                    <Grid item xs={12}>
                                        <Button variant="contained" startIcon={<PhoneIcon />} className="newproduct-btn" fullWidth color="primary" onClick={() => getSellerContactDetails(newProductLaunchId)} >
                                            call
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" className="newproduct-btn" color="primary" fullWidth onClick={() => handleImInterested(singleProductData.id)}>
                                            {currentUser.userType === "VENDOR" ? "Interested in dealership" : "I'm Interested"}
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Paper>
                        ) : (
                            <Grid container style={{ marginTop: "10px" }}>
                                <Grid item xs={12}>
                                    <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                        No results found
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </div>

                    <div>
                        <ImageEnlargeDialog
                            enlargeImgDialog={enlargeImgDialog}
                            handleEnlargeImgDialogClose={handleEnlargeImgDialogClickClose}
                            clickedImage={clickedImage}
                        />
                    </div>

                    <div>
                        <Dialog open={openAlertVerificationDialog} onClose={handleAlertVerificationDialogClickClose}>
                            <DialogTitle >MeddyConnect</DialogTitle>
                            <DialogContent>

                                <Typography variant="subtitle1" color="text.primary" align="left">
                                    You cannot contact seller as you are not a verified user
                                </Typography>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleAlertVerificationDialogClickClose} color="primary" >Cancel</Button>
                                <Button onClick={handleVerifyClick} color="primary" >Verify</Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                    <div>
                        <Dialog open={openShowSellerContactDetailsDialog} onClose={handleShowSellerContactDetailsDialogClickClose}
                            maxWidth="sm" fullWidth TransitionComponent={Transition}
                        >
                            <DialogTitle >Seller Details</DialogTitle>
                            <DialogContent>

                                <Typography variant="subtitle1" color="text.primary" align="left">
                                    <span className='bold-text'> Seller Name : </span> {sellerName}
                                </Typography>
                                <Typography variant="subtitle1" color="text.primary" align="left">
                                    <span className='bold-text'> Contact No : </span> <a href={`tel:${sellerContactNumber}`} style={{ color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1", textDecoration: "none" }}>{sellerContactNumber}</a>
                                </Typography>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleShowSellerContactDetailsDialogClickClose} color="primary" >Cancel</Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                    {/* ############################ PDF viewer dialog ######################################### */}

                    <Dialog
                        open={openDocumentDialog}
                        onClose={handleDocumentDialogClickClose}
                        fullScreen={fullScreen}
                        maxWidth="md"
                        fullWidth
                        TransitionComponent={Transition}
                    >
                        <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                            <IconButton
                                edge="start"
                                onClick={handleDocumentDialogClickClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                }}
                            >
                                <CloseIcon style={{ color: "white" }} />
                            </IconButton>
                            View Document
                        </DialogTitle>
                        <DialogContent style={{ padding: "0px" }}>

                            <PDFViewer pdfData={`data:${selectedPDF.contentType};base64,${selectedPDF.content}`} />

                        </DialogContent>
                    </Dialog>

                </div>

                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default NewProductLaunchDetails
