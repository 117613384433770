import React, { useState, useContext, useEffect } from 'react';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import {
  Avatar, Typography, Box, Card, TextField, InputAdornment, Divider,
  IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Slide,
} from '@mui/material';
import { YContext } from "../../Context/YContext";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import useAPIRequest from "../../API/useApiRequest";
import { useToasts } from "react-toast-notifications";
import Constants from "../../MeddyConnectConstant";
import UrlRoute from "../../API/UrlRoute";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import "./visitorregistration.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VisitorRegistration = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { addToast } = useToasts();
  const history = useHistory();

  const [visitorName, setVisitorName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [designation, setDesignation] = useState("");

  const numberOfVisitors = 1;
  const visitorCost = 500;
  const platformFee = 50;
  const subtotal = numberOfVisitors * visitorCost;
  const total = subtotal + platformFee;

  // Services
  const { currentUser } = useContext(YContext);

  // Dialogs
  const [openAddVisitorDialog, setOpenAddVisitorDialog] = useState(false);

  const handleAddVisitorDialogClickOpen = () => {
    setOpenAddVisitorDialog(true);
  };

  const handleAddVisitorDialogClickClose = () => {
    setOpenAddVisitorDialog(false);
  };

  return (
    <div style={{ height: "auto" }}>

      <div style={{ display: "flex" }}>

        <div className="visitor-registration-drawer">
          <Navigation />
        </div>

        <div className="visitor-registration-container">

          <Box className="add-visitor-btn-container" sx={{ mt: { xs: 0, sm: 2 }, mb: { xs: 0, sm: 2.5 }, mx: { xs: 1.3, sm: 2 } }}>
            <Button variant="contained" endIcon={< AddIcon />} color="primary" onClick={handleAddVisitorDialogClickOpen} className="add-visitor-btn">
              Add Visitor
            </Button>
          </Box>

          <Card
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: 1.5,
              m: 1.5,
              mx: { xs: 1.3, sm: 2 },
              p: { xs: 1.3, sm: 2 },
              boxShadow: 6,
              backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929",
              borderRadius: "12px"
            }}
          >

            <Avatar
              src={currentUser.profileLink && currentUser.profileLink.href ? currentUser.profileLink.href : " "}
              color="primary"
              style={{ height: "45px", width: "45px", marginTop: "7px" }}
            />

            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" align="left" color="text.primary">
                {currentUser.name}
              </Typography>
              <Typography variant="body1" align="left" color="text.primary">
                {currentUser.contactNo}
              </Typography>
              <Typography variant="body1" align="left" color="text.primary">{currentUser.email}</Typography>
              <Typography variant="body1" align="left" color="text.primary">
                {currentUser?.userType
                  ? currentUser.userType.charAt(0).toUpperCase() + currentUser.userType.slice(1).toLowerCase()
                  : "Not specified"}
              </Typography>
            </Box>

            <IconButton
              aria-label="delete"
              // onClick={() => deleteUserAwards(userType, value.id)}
              sx={{
                alignSelf: "flex-start",
                p: 0,
                marginTop: "7px"
              }}
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </Card>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: 1.5,
              m: 1.5,
              mx: { xs: 1.3, sm: 2 },
              p: { xs: 1.3, sm: 2 },
              boxShadow: 6,
              backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929",
              borderRadius: "12px"
            }}
          >
            <Typography variant="h6" align="left" color="text.primary">
              Price Details
            </Typography>

            {/* Subtotal */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="body1" align="left" color="text.primary">Price ({numberOfVisitors} Visitors)</Typography>
              <Typography variant="body1" align="left" color="text.primary">₹{subtotal}</Typography>
            </Box>

            {/* Platform Fee */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="body1" align="left" color="text.primary">Platform Fee</Typography>
              <Typography variant="body1" align="left" color="text.primary">₹{platformFee}</Typography>
            </Box>

            {/* Divider */}
            <Divider sx={{ height: "4px", width: "100%" }} />

            {/* Total */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="h6" align="left" color="text.primary">Total</Typography>
              <Typography variant="h6" align="left" color="text.primary">₹{total}</Typography>
            </Box>

          </Box>

          {/* {!isApiCallInProgress && ( */}
          <Box sx={{
            m: 2,
            mx: { xs: 1.3, sm: 2 },
          }}>
            <Button variant="contained" fullWidth className='add-visitor-btn' color="primary" > Pay & Reserve slot </Button>
          </Box>
          {/* )} */}

          <Dialog
            open={openAddVisitorDialog}
            onClose={handleAddVisitorDialogClickClose}
            fullScreen={fullScreen}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Transition}
          >
            <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
              <IconButton
                edge="start"
                onClick={handleAddVisitorDialogClickClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon style={{ color: "white" }} />
              </IconButton>
              Add Visitor
            </DialogTitle>
            <DialogContent sx={{ padding: "20px 24px 0px 24px" }}>

              <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                mt: 1
              }}>
                <TextField fullWidth
                  label="Visitor Name"
                  id="visitor-name"
                  name="visitorName"
                  value={visitorName}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const formattedValue = inputValue
                      .split(" ")
                      .map((word) => {
                        if (word === word.toUpperCase()) {
                          return word;
                        } else {
                          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                        }
                      })
                      .join(" ");

                    setVisitorName(formattedValue);
                  }}
                  autoComplete="off"
                  variant="outlined"
                />

                <TextField
                  color="primary"
                  label="WhatsApp number"
                  variant="outlined"
                  id="mobile-number"
                  name="mobileNumber"
                  autoComplete="off"
                  value={mobileNumber}
                  onChange={(event) => setMobileNumber(event.target.value)}
                  inputProps={{ maxLength: 10, type: "tel" }}
                  fullWidth
                />

                <TextField
                  fullWidth
                  placeholder="Email ID"
                  type="email"
                  variant="outlined"
                  id="email-id"
                  name="emailId"
                  value={emailId}
                  onChange={(event) => setEmailId(event.target.value)}
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField fullWidth
                  label="Designation"
                  id="designation"
                  name="designation"
                  value={designation}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const formattedValue = inputValue
                      .split(" ")
                      .map((word) => {
                        if (word === word.toUpperCase()) {
                          return word;
                        } else {
                          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                        }
                      })
                      .join(" ");

                    setDesignation(formattedValue);
                  }}
                  autoComplete="off"
                  variant="outlined"
                />
              </Box>

            </DialogContent>
            <DialogActions>
              <Button variant="contained" fullWidth className='add-button' color="primary" > Add </Button>
              {/* onClick={handleApplyForJobSubmit} */}
            </DialogActions>
          </Dialog>

        </div>
      </div>
      <YLoader loading={loading} />
    </div>
  )
}

export default VisitorRegistration