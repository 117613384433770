import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from "firebase/auth";
import Constants from "../src/script/MeddyConnectConstant";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
const messaging = getMessaging(app);

// Initialize Firestore
const firestore = getFirestore(app);

// Initialize Firebase Storage
const storage = getStorage(app);

const firebaseAuth = getAuth(app);

// Function to request permission and retrieve token if necessary
async function requestPermissionAndToken() {
  try {
    const permission = Notification.permission;

    if (permission === "granted") {
      return await getAndStoreToken();
    } else if (permission === "default") {
      const newPermission = await Notification.requestPermission();
      if (newPermission === "granted") {
        return await getAndStoreToken();
      } else {
        console.warn("Notification permission denied.");
        return null;
      }
    } else {
      console.warn("Notification permission previously denied.");
      return null;
    }
  } catch (error) {
    console.error("An error occurred while requesting permission:", error);
    return null;
  }
}

// Function to retrieve and store token
async function getAndStoreToken() {
  try {
    const storedToken = localStorage.getItem(Constants.DEVICE_TOKEN);
    const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FB_VAPID_KEY });

    if (currentToken) {
      if (!storedToken || currentToken !== storedToken) {
        console.log("Storing new device token:", currentToken);
        return currentToken;
      } else {
        console.log("Using existing device token:", storedToken);
        return storedToken;
      }
    } else {
      console.warn("No registration token available.");
      return null;
    }
  } catch (error) {
    console.error("An error occurred while retrieving the token:", error);
    return null;
  }
}

// requestPermissionAndToken();

export { messaging, firestore, storage, firebaseAuth, requestPermissionAndToken };