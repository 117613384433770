import React, { useState, useContext, useEffect } from 'react';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import {
  Grid, TextField, Paper, Button, InputLabel, Select, MenuItem, FormControl, Box,
  InputAdornment, Typography, IconButton, Radio, RadioGroup, FormLabel, FormControlLabel,
} from '@mui/material';
import { YContext } from "../../Context/YContext";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import useAPIRequest from "../../API/useApiRequest";
import { useToasts } from "react-toast-notifications";
import useGetData from '../../API/useGetData';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Constants from "../../MeddyConnectConstant";
import UrlRoute from "../../API/UrlRoute";
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import EditIcon from '@mui/icons-material/Edit';
import eventbackgroundimg from "../../assets/images/eventbackgroundimg.jpg";
import "./createevent.css";

const CreateEvent = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const history = useHistory();
  const { addToast } = useToasts();

  const [eventTitle, setEventTitle] = useState("");
  const [description, setDescription] = useState('');
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const today = new Date();
  const [ticketPrice, setTicketPrice] = useState("");
  const [seatingCapacity, setSeatingCapacity] = useState("");
  const [eventUrl, setEventUrl] = useState('');
  const [eventType, setEventType] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [stateChoices, setStateChoices] = useState("");
  const [cityChoices, setCityChoices] = useState("");
  const [countryChoices, setCountryChoices] = useState("");
  const [pinCode, setPinCode] = useState("");

  // Services
  const { currentUser } = useContext(YContext);
  const { apiRequests } = useAPIRequest();
  const { getCountry, getStates, getCity } = useGetData();

  useEffect(() => {
    getReferenceData();
  }, []);

  const getReferenceData = async () => {
    const countryOptions = await getCountry(addToast);
    if (countryOptions) {
      setCountry("");
      setCountryChoices(countryOptions);
    }
  };

  const getStatesData = async (country) => {
    if (country && country != "") {
      let stateOptions = [];
      stateOptions = await getStates(country, addToast);
      if (stateOptions) {
        setState("");
        setStateChoices(stateOptions);
      }
    }
  };

  const getCityData = async (country, state) => {
    if (country && country != "" && state && state != "") {
      let cityOptions = [];
      cityOptions = await getCity(country, state, addToast);
      if (cityOptions) {
        setCity("");
        setCityChoices(cityOptions);
      }
    }
  };

  const handleEventTypeChange = (event) => {
    setEventType(event.target.value);
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const imageURL = URL.createObjectURL(event.target.files[0]);
      setSelectedImage(imageURL);
    }
  };

  const handleImageDelete = () => {
    setSelectedImage(null);
  };

  // const validateJobForm = () => {

  //   let valid = true;

  //   if (jobTitle.trim() === '') {
  //     addToast('Job title cannot be empty', { appearance: 'error' });
  //     valid = false;
  //   }

  //   if (description.trim() === '') {
  //     addToast('Description cannot be empty', { appearance: 'error' });
  //     valid = false;
  //   }

  //   if (qualification.trim() === '') {
  //     addToast('Qualification cannot be empty', { appearance: 'error' });
  //     valid = false;
  //   }

  //   if (!jobType) {
  //     addToast('Please select the job type', { appearance: 'error' });
  //     valid = false;
  //   }

  //   if (!/^[0-9]*$/.test(expectedSalary)) {
  //     addToast('Expected salary must contain only numbers', { appearance: 'error' });
  //     valid = false;
  //   }

  //   if (country.trim() === '') {
  //     addToast('Country cannot be empty', { appearance: 'error' });
  //     valid = false;
  //   }

  //   if (state.trim() === '') {
  //     addToast('State cannot be empty', { appearance: 'error' });
  //     valid = false;
  //   }

  //   if (city.trim() === '') {
  //     addToast('City cannot be empty', { appearance: 'error' });
  //     valid = false;
  //   }

  //   if (pinCode.trim() === '') {
  //     addToast('PinCode cannot be empty', { appearance: 'error' });
  //     valid = false;
  //   } else {
  //     // Check if pincodes contains only numbers
  //     const numericRegex = /^[0-9]+$/;

  //     if (!numericRegex.test(pinCode)) {
  //       addToast('Invalid PinCode. Please enter only numbers.', { appearance: 'error' });
  //       valid = false;
  //     } else if (pinCode.length !== 6) {
  //       addToast('PinCode must be 6 digits long', { appearance: 'error' });
  //       valid = false;
  //     }
  //   }

  //   return valid;
  // };

  // const createJobApplication = async () => {
  //   setIsApiCallInProgress(true);
  //   setLoading(true);

  //   const { response, data, error } = await apiRequests({
  //     endPoint: UrlRoute.JOBS_URL,
  //     method: "POST",
  //     body: {
  //       title: jobTitle,
  //       description: description,
  //       qualification: qualification,
  //       jobType: jobType,
  //       expectedSalary: expectedSalary,
  //       country: country,
  //       state: state,
  //       city: city,
  //       pinCode: pinCode,
  //       skills: skillsData,
  //       tags: hashtagsData
  //     },
  //     addToast,
  //   });

  //   if (data) {

  //     setLoading(false);
  //     setIsApiCallInProgress(false);
  //     addToast("Posted the job successfully !!!", { appearance: "success" });
  //     history.push(Constants.MANAGE_JOB_APPLICATIONS);

  //   } else {
  //     setLoading(false);
  //     setIsApiCallInProgress(false);
  //     addToast("Something went wrong!", { appearance: "error" });
  //   }
  // };

  // const handlePostAJobSubmit = () => {

  //   if (validateJobForm()) {
  //     createJobApplication();
  //   }

  // };

  return (
    <>
      <div style={{ height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="create-event-drawer">
            <Navigation />
          </div>

          <div className="create-event-container">

            <Paper elevation={2} className="event-paper-container">

              <Box display="flex" flexDirection="column" gap={1}>

                <Box style={{ marginBottom: "-12px" }}>
                  <FormControl>
                    <FormLabel id="event-type-radio-btngroup" style={{ color: theme.palette.mode === "light" ? "#000000" : "#fff" }} className='event-type-label'>Event Type :</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="event-type-radio-btngroup"
                      name="event-type-radio-btn"
                      value={eventType}
                      onChange={handleEventTypeChange}
                    >
                      <FormControlLabel
                        value="online"
                        control={<Radio />}
                        label="Online"
                      />
                      <FormControlLabel
                        value="inperson"
                        control={<Radio />}
                        label="In Person"
                      />
                      <FormControlLabel
                        value="hybrid"
                        control={<Radio />}
                        label="Hybrid"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box>
                  <TextField fullWidth
                    label="Event Name"
                    name="eventName"
                    value={eventTitle}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const formattedValue = inputValue
                        .split(" ")
                        .map((word) => {
                          if (word === word.toUpperCase()) {
                            return word;
                          } else {
                            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                          }
                        })
                        .join(" ");

                      setEventTitle(formattedValue);
                    }}
                    autoComplete="off"
                    id="eventName"
                    variant="outlined"
                  />
                </Box>
                <Box>
                  <TextField fullWidth
                    label="Event Description"
                    name="description"
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    autoComplete="off"
                    multiline
                    minRows={3}
                    id="description"
                    variant="outlined"
                  />
                </Box>
                <Box display="flex"
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' }, // Column below 600px, row above 600px
                    gap: 2, // Space between the two DateTimePickers
                    width: '100%', // Takes full width
                  }}
                >
                  <Box flex={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {/* Start DateTimePicker */}
                      <DateTimePicker
                        label="Start Date and Time"
                        sx={{ width: '100%' }}
                        value={startDateTime}
                        onChange={(newValue) => setStartDateTime(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                        minDateTime={today} // Prevents selecting past dates/times for start
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box flex={1} sx={{ marginTop: { xs: '-8px', sm: 0 } }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {/* End DateTimePicker */}
                      <DateTimePicker
                        label="End Date and Time"
                        sx={{ width: '100%' }}
                        value={endDateTime}
                        onChange={(newValue) => setEndDateTime(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                        minDateTime={startDateTime || today} // Prevents selecting past dates/times for end, ensures end date is after start date
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box>
                  <TextField fullWidth
                    label="Event Ticket Price"
                    name="ticketPrice"
                    value={ticketPrice}
                    onChange={(event) => setTicketPrice(event.target.value)}
                    autoComplete="off"
                    id="ticketPrice"
                    variant="outlined"
                  />
                </Box>
                <Box>
                  <TextField fullWidth
                    label="Seating Capacity"
                    name="capacity"
                    value={seatingCapacity}
                    onChange={(event) => setSeatingCapacity(event.target.value)}
                    autoComplete="off"
                    id="capacity"
                    variant="outlined"
                  />
                </Box>
                <Box>
                  <TextField fullWidth
                    label="Event URL"
                    name="eventUrl"
                    id="eventUrl"
                    value={eventUrl}
                    onChange={(event) => setEventUrl(event.target.value)}
                    type="url"
                    autoComplete="off"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InsertLinkIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box>
                  <TextField fullWidth
                    label="Address"
                    name="address"
                    value={address}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const formattedValue = inputValue
                        .split(" ")
                        .map((word) => {
                          if (word === word.toUpperCase()) {
                            return word;
                          } else {
                            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                          }
                        })
                        .join(" ");

                      setAddress(formattedValue);
                    }}
                    autoComplete="off"
                    multiline
                    maxRows={4}
                    id="address"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PinDropOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                {/* ############################  Event upload images section ######################################### */}

                <Box>
                  {!selectedImage && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      className="event-upload-container"
                    >
                      <IconButton component="label" style={{ padding: "0px" }}>
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={handleImageChange}
                        />
                        <CloudUploadIcon color="primary" className="event-upload-icon" />
                      </IconButton>
                      <Typography variant="subtitle1" color="text.primary" align="center" gutterBottom>
                        Upload Image
                      </Typography>
                    </Box>
                  )}

                  {selectedImage && (
                    <Box position="relative" height="300px">
                      <img src={selectedImage} alt="upload" style={{ width: "100%", height: "100%", objectFit: "fill" }} />
                      <IconButton
                        onClick={handleImageDelete}
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "6px",
                          fontSize: fullScreen ? "18px" : "22px",
                          color: "red",
                          cursor: "pointer"
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>

                <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={1}>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel id="country-select-label" htmlFor="country-picker">
                        Country
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="country-label"
                        id="country-picker"
                        label="Select Country"
                        value={country}
                        onChange={(event) => {
                          setCountry(event.target.value);
                          setStateChoices([]);
                          setCityChoices([]);
                          getStatesData(event.target.value);
                        }}
                      >
                        <MenuItem value={""} selected>
                          Select Country
                        </MenuItem>
                        {Array.isArray(countryChoices) &&
                          countryChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>{choice}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl fullWidth >
                      <InputLabel id="state-select-label" htmlFor="state-picker">
                        State
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="state-label"
                        id="state-picker"
                        label="Select State"
                        value={state}
                        onChange={(event) => {
                          setState(event.target.value);
                          setCityChoices([]);
                          getCityData(country, event.target.value);
                        }}
                      >
                        <MenuItem value={""}>Select State</MenuItem>
                        {Array.isArray(stateChoices) &&
                          stateChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>{choice}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>

                <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={1}>
                  <Box flex={1}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-dialog-select-label" htmlFor="city-picker">
                        City
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="city-label"
                        id="city-picker"
                        label="Select City"
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                      >
                        <MenuItem value={""}>Select City</MenuItem>
                        {Array.isArray(cityChoices) &&
                          cityChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>{choice}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <TextField fullWidth
                      label="Pincode"
                      name="pincode"
                      value={pinCode}
                      onChange={(event) => setPinCode(event.target.value)}
                      autoComplete="off"
                      id="pincode"
                      variant="outlined"
                    />
                  </Box>
                </Box>

              </Box>

            </Paper>

            {
              !isApiCallInProgress && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  style={{ padding: fullScreen ? "0px 6px" : "0px 10px" }}
                >
                  <Box flex={1}>
                    <Button
                      variant="contained"
                      fullWidth
                      className='event-button'
                      color="primary"
                    // onClick={handlePostAJobSubmit}
                    >
                      Create Event
                    </Button>
                  </Box>
                </Box>
              )
            }

          </div>
        </div>
        <YLoader loading={loading} />
      </div>
    </>

  )
}

export default CreateEvent
