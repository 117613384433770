import { useEffect, useState, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { IconButton, Avatar, Typography, Grid } from "@mui/material";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import FlagIcon from '@mui/icons-material/Flag';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CommentDialog from "./CommentDialog";
import ReportDialog from "./ReportDialog";
import InfiniteScroll from "react-infinite-scroll-component";
import useAPIRequest from "../../API/useApiRequest";
import UrlRoute from "../../API/UrlRoute";
import { useToasts } from "react-toast-notifications";
import "./shortsfeed.css";

const ShortsFeed = () => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const [videoUrls, setVideoUrls] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [showControls, setShowControls] = useState(false);
  const [expandedCaptionIndex, setExpandedCaptionIndex] = useState(null);
  const videoRefs = useRef([]);
  const [selectedVideoId, setSelectedVideoId] = useState(null);

  // Services
  const { apiRequests } = useAPIRequest();

  // Dialogs
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);

  useEffect(() => {
    fetchVideos(pageNo);
  }, []);

  useEffect(() => {
    if (videoRefs.current[currentIndex]) {
      if (isPlaying) {
        videoRefs.current[currentIndex].play();
      } else {
        videoRefs.current[currentIndex].pause();
      }
    }
  }, [currentIndex, isPlaying]);

  const handleSwipe = (deltaY) => {
    if (deltaY < 0 && currentIndex < videoUrls.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setIsPlaying(true); // Auto-play next video
    } else if (deltaY > 0 && currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      setIsPlaying(true); // Auto-play previous video
    }
  };

  const handlers = useSwipeable({
    onSwipedUp: (eventData) => handleSwipe(eventData.deltaY),
    onSwipedDown: (eventData) => handleSwipe(eventData.deltaY),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const togglePlayPause = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement.paused) {
      videoElement.play();
      setIsPlaying(true);
    } else {
      videoElement.pause();
      setIsPlaying(false);
    }
  };

  const handleVideoClick = (index) => {
    togglePlayPause(index);
    setShowControls(true);
    setTimeout(() => setShowControls(false), 2000); // Hide controls after 2 seconds
  };

  const handleCaptionToggle = (index) => {
    setExpandedCaptionIndex(expandedCaptionIndex === index ? null : index);
  };

  const handleOpenCommentDialog = (videoId) => {
    setSelectedVideoId(videoId);
    setOpenCommentDialog(true);
  };

  const handleCloseCommentDialog = () => {
    setOpenCommentDialog(false);
    setSelectedVideoId(null);
  };

  const handleOpenReportDialog = (shortId) => {
    setSelectedVideoId(shortId);
    setOpenReportDialog(true);
  };

  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
    setSelectedVideoId(null);
  };

  const fetchVideos = async (pageNo) => {
    setLoading(true);
    try {
      const { response, data, error } = await apiRequests({
        endPoint: `${UrlRoute.GET_ALL_SHORTS_URL}?page=${pageNo}`,
        method: "GET",
        addToast,
      });

      if (data) {

        setVideoUrls((prevData) => {
          const uniqueEntities = data.entities.filter(newEntity =>
            !prevData.some(existingEntity => existingEntity.id === newEntity.id)
          );
          return [...prevData, ...uniqueEntities];
        });

        setTotalPages(data.totalPages);
        setPageNo(data.page);

      } else {
        addToast("Something went wrong!", { appearance: "error" });
      }
    } catch (error) {
      addToast("Error fetching videos:", { appearance: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleLikeClick = async (videoId) => {

    try {
      const { response } = await apiRequests({
        endPoint: `${UrlRoute.UPDATE_LIKE_STATUS_URL}/${videoId}`,
        method: "PUT",
        body: {},
        addToast,
      });
      if (response.status === 200) {

        setVideoUrls(videoUrls.map((item) => {
          if (item.id === videoId) {
            const updatedDidUserLiked = !item.didUserLiked;
            return {
              ...item,
              didUserLiked: updatedDidUserLiked,
              noOfLikes: updatedDidUserLiked ? item.noOfLikes + 1 : item.noOfLikes - 1,
            }
          } else {
            return item
          }
        }))

      } else {
        addToast("Failed to update like status", { appearance: "error" });
      }
    } catch (error) {
      addToast("Error updating like status", { appearance: "error" });
    }

  };

  const handleCommentAdded = (videoId) => {

    setVideoUrls((prevVideoUrls) =>
      prevVideoUrls.map((video) => {
        if (video.id === videoId) {
          return {
            ...video,
            noOfMessages: video.noOfMessages + 1,
          };
        }
        return video;
      })
    );
  };

  const handleCopyClick = async (shortId) => {
    console.log(shortId)
    try {

      // const shortUrl = `${process.env.REACT_APP_URL}${UrlRoute.GENERATE_TOKEN_URL}`;
      const shortUrl = `http://localhost:3000/short/${shortId}`;

      await navigator.clipboard.writeText(shortUrl);
      addToast("URL copied to clipboard!", { appearance: 'success' });
    } catch (err) {
      addToast("Failed to copy URL", { appearance: 'error' });
      //   console.error('Failed to copy: ', err);
    }
  };

  return (
    <>
      <div {...handlers} style={{ height: "100vh", overflow: "hidden" }}>
        <div style={{ display: "flex" }}>
          <div className="video-feed-drawer">
            <Navigation />
          </div>
          <div className="video-feed-container">
            <InfiniteScroll
              style={{ overflow: "hidden" }}
              dataLength={videoUrls.length}
              next={() => {
                fetchVideos(pageNo + 1);
              }}
              hasMore={!(pageNo + 1 === totalPages)}
              loader={<YLoader loading={loading} />}
              endMessage={<span></span>}
            >
              {videoUrls.length > 0 ? (
                videoUrls.map((video, index) => {
                  if (index !== currentIndex) return null; // Only render the current video

                  const isExpanded = expandedCaptionIndex === index;
                  const showMore = video.caption && video.caption.length > 70;
                  return (
                    <div className={`video-item ${index === currentIndex ? "active" : ""}`} key={video.id}>
                      <video
                        className="reel-video"
                        src={video.shortLink.href}
                        controls={false}
                        autoPlay={index === currentIndex}
                        loop
                        playsInline
                        muted={!isPlaying} // Audio based on video play state
                        ref={el => videoRefs.current[index] = el}
                        onClick={() => handleVideoClick(index)}
                      ></video>
                      {showControls && (
                        <div className="video-controls">
                          <IconButton
                            className="video-control-button"
                            onClick={() => togglePlayPause(index)}>
                            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                          </IconButton>
                        </div>
                      )}
                      <div className="video-actions">

                        <div className="video-action-item">
                          <IconButton className="video-buttons" onClick={() => handleLikeClick(video.id)}>
                            <FavoriteIcon style={{ color: video.didUserLiked ? 'red' : 'inherit' }} />
                          </IconButton>
                          <Typography variant="body2" color="text.primary">{video.noOfLikes}</Typography>
                        </div>
                        <div className="video-action-item">
                          <IconButton className="video-buttons" onClick={() => handleOpenCommentDialog(video.id)}>
                            <CommentIcon />
                          </IconButton>
                          <Typography variant="body2" color="text.primary">{video.noOfMessages}</Typography>
                        </div>
                        <div className="video-action-item">
                          <IconButton className="video-buttons" onClick={() => handleCopyClick(video.id)}>
                            <ShareIcon />
                          </IconButton>
                        </div>

                      </div>

                      <div className="video-report-container">
                        <IconButton className="video-report-button" onClick={() => handleOpenReportDialog(video.id)}>
                          <FlagIcon />
                        </IconButton>
                      </div>

                      <div className="video-info">
                        <Avatar src={video.profileLink && video.profileLink.href ? video.profileLink.href : " "} className="user-avatar" />
                        <div className="video-caption-container">
                          <div className={`video-caption ${isExpanded ? "expanded" : ""}`}>

                            <Typography variant="subtitle2" color="text.primary">{video.userName}</Typography>
                            {video.caption && video.caption.trim() !== "" && (
                              <Typography variant="body2" color="text.primary">{video.caption}</Typography>
                            )}

                          </div>
                          {showMore && (
                            <IconButton
                              onClick={() => handleCaptionToggle(index)}
                              className="expand-button"
                            >
                              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Grid container style={{ marginTop: "10px" }}>
                  <Grid item xs={12}>
                    <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                      No results found
                    </Typography>
                  </Grid>
                </Grid>
              )
              }

            </InfiniteScroll>
          </div>
        </div>

        <YLoader loading={loading} />
      </div >

      {/* Comment Dialog */}
      < CommentDialog
        openCommentDialog={openCommentDialog}
        handleCloseCommentDialog={handleCloseCommentDialog}
        selectedVideoId={selectedVideoId}
        onCommentAdded={handleCommentAdded}
      />

      {/* Report short Dialog */}
      < ReportDialog
        openReportDialog={openReportDialog}
        handleCloseReportDialog={handleCloseReportDialog}
        selectedVideoId={selectedVideoId}
      />

    </>
  )
}

export default ShortsFeed