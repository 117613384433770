import usePublicAPIRequest from '../API/useApiPublicRequest';
import Constants from "../MeddyConnectConstant";
import UrlRoute from "../API/UrlRoute";
import { useToasts } from "react-toast-notifications";
import { useHistory, useLocation } from 'react-router-dom';


const useTokenService = () => {

    const { addToast } = useToasts();
    const { apiPublicRequests } = usePublicAPIRequest();
    const history = useHistory();
    const location = useLocation();

    const getSavedCredentials = () => {

        const countryCode = localStorage.getItem(Constants.COUNTRY_CODE);
        if (!countryCode) {
            return { countryCode: "", contactNo: "", password: "", deviceToken: "" };
        }
        const contactNo = localStorage.getItem(Constants.CONTACT_NO);
        const password = localStorage.getItem(Constants.PASSWORD);
        const deviceToken = localStorage.getItem(Constants.DEVICE_TOKEN);
        return {
            countryCode: countryCode,
            contactNo: contactNo,
            password: password,
            deviceToken: deviceToken
        };
    }

    const saveCredentials = (countryCode, contactNo, password, storedDeviceToken) => {
        localStorage.setItem(Constants.COUNTRY_CODE, countryCode);
        localStorage.setItem(Constants.CONTACT_NO, contactNo);
        localStorage.setItem(Constants.PASSWORD, password);
        localStorage.setItem(Constants.DEVICE_TOKEN, storedDeviceToken);
    }

    const saveToken = (token) => {
        localStorage.setItem(Constants.TOKEN, token);
        localStorage.setItem(Constants.TOKEN_GENERATION_TIME, new Date().toISOString());
    }

    const getOrGenerateToken = () => {
        const token = localStorage.getItem(Constants.TOKEN);

        if (!token) {
            return generateToken();
        } else {
            const tokenGenerationTime = new Date(localStorage.getItem(Constants.TOKEN_GENERATION_TIME));
            const currentTime = new Date();
            const expireTime = new Date(tokenGenerationTime.getTime() + Constants.TOKEN_EXPIRE * 60 * 1000);

            if (expireTime > currentTime) {
                return token;
            } else {
                return generateToken();
            }

        }
    }

    const generateToken = () => {

        const credentials = getSavedCredentials();

        if (credentials.countryCode !== "") {
            return _generateToken(
                credentials.countryCode,
                credentials.contactNo,
                credentials.password,
                credentials.deviceToken
            );
        } else {
            return ("ApplicationErrors.no_saved_credentials");
        }
    }

    const _generateToken = async (countryCode, contactNo, password, deviceToken) => {

        const { response, data, error } = await apiPublicRequests(
            {
                endPoint: UrlRoute.GENERATE_TOKEN_URL,
                method: "POST",
                body: { countryCode, contactNo, password, deviceToken },
                addToast
            }
        );
        if (data) {
            const token = data.token;
            saveToken(token);
            return token;
        } else if (response.status === 500) {
            addToast("Server error occurred. Please log in again.", { appearance: 'error' }); 
            if (history && location.pathname !== '/login') {
                history.push('/login');
            }
            return null;
        }
        else {
            return null;
        }
    };

    return {
        saveCredentials,
        getOrGenerateToken,
        saveToken,
    }

}

export default useTokenService