import React, { useState, useContext, useEffect } from 'react';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import {
    Card, CardContent, CardMedia, Typography, Button, TextField,
    IconButton, InputAdornment, CardActions
} from '@mui/material';
import { YContext } from "../../Context/YContext";
import PlaceIcon from '@mui/icons-material/Place';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import useAPIRequest from "../../API/useApiRequest";
import { useToasts } from "react-toast-notifications";
import useGetData from '../../API/useGetData';
import Constants from "../../MeddyConnectConstant";
import UrlRoute from "../../API/UrlRoute";
import { format, parseISO } from 'date-fns';
import CurrencyFormat from "../../utils/CurrencyFormat";
import "./alleventcards.css";

const formatDate = (date) => format(parseISO(date), 'd MMM');
const formatTime = (date) => format(parseISO(date), 'hh:mm a');


const AllEventCards = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { addToast } = useToasts();
    const history = useHistory();


    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="event-card-drawer">
                        <Navigation />
                    </div>

                    <div className="event-cards-container">

                        {/* Search Bar and Filter */}
                        <div className="search-bar" style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000" }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Search events"
                                name="searchbar"
                                id="searchbar"
                                autoComplete="off"
                                // value={searchTerm}
                                // onChange={(event) => handleSearchResultChange(event.target.value)}
                                style={{ margin: 0 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <IconButton aria-label="filter">
                                <TuneOutlinedIcon className='event-filter-icon' color="primary" />
                            </IconButton>
                        </div>

                        {/* Event Cards */}
                        <div className="event-cards-scroll">
                            <div className="event-cards">
                                {events.map((event, index) => {
                                    const startDateTime = event.startDate;
                                    const endDateTime = event.endDate;
                                    const isMultiDayEvent = formatDate(startDateTime) !== formatDate(endDateTime);

                                    return (
                                        <div key={event.id} className="event-card">
                                            <Card style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                <CardMedia
                                                    component="img"
                                                    height="180"
                                                    image={event.eventimage}
                                                    style={{ objectFit: "fill" }}
                                                    alt={event.eventname}
                                                />
                                                <CardContent style={{ flexGrow: 1 }}>
                                                    <Typography variant="h6" align="left" color="text.primary" gutterBottom className="event-title">
                                                        {event.eventname}
                                                    </Typography>
                                                    <Typography variant="subtitle1" color="text.primary" align="left" style={{ fontWeight: "700" }}>
                                                        <CurrencyFormat currencyPrice={event.price} />
                                                    </Typography>
                                                    <Typography variant="subtitle1" color="text.primary" gutterBottom className="event-description" >
                                                        {event.eventDescription}
                                                    </Typography>
                                                    <Typography variant="body1" color="text.primary" gutterBottom style={{ display: "flex", alignItems: "flex-start" }}>                                                   
                                                        <CalendarTodayIcon color="primary" style={{ fontSize: fullScreen ? "20px" : "22px", verticalAlign: "middle", marginRight: "4px", marginLeft: "-4px" }} />
                                                        {!isMultiDayEvent ? (
                                                            <>
                                                                {formatDate(startDateTime)} | {formatTime(startDateTime)} - {formatTime(endDateTime)}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {formatDate(startDateTime)} - {formatDate(endDateTime)} | {formatTime(startDateTime)} - {formatTime(endDateTime)}
                                                            </>
                                                        )}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.primary" gutterBottom style={{ display: "flex", alignItems: "flex-start" }}>
                                                        <PlaceIcon color="primary" style={{ fontSize: fullScreen ? "20px" : "24px", verticalAlign: "middle", marginRight: "4px", marginLeft: "-4px" }} />
                                                        {event.address}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions disableSpacing style={{ justifyContent: "flex-end", padding: "16px" }}>
                                                    <Button variant="contained" color="primary" className="view-details-button" >
                                                        View Details
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                    </div>
                </div>
                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default AllEventCards


const events =
    [
        {
            id: 0,
            eventimage: "https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
            eventname: "NYC Food Festival",
            eventDescription: " This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels,if you like.",
            address: "Central Park Manhattan ,New York City.",
            startDate: '2024-10-22T14:00:00',
            endDate: '2024-10-22T16:00:00',
            price: 2000,
        },
        {
            id: 1,
            eventimage: "https://images.unsplash.com/photo-1569863959165-56dae551d4fc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGV2ZW50c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60",
            eventname: "Charity Events",
            eventDescription: " This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels,if you like.",
            address: " Radisson Blu, Dwarka",
            startDate: '2024-10-22T14:00:00',
            endDate: '2024-11-24T10:00:00',
            price: 500
        },
        {
            id: 2,
            eventimage: "https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
            eventname: "NYC Food Festival",
            eventDescription: " This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels,if you like.",
            address: "Central Park Manhattan ,New York City.",
            startDate: '2024-10-22T14:00:00',
            endDate: '2024-10-22T16:00:00',
            price: 40000
        },
        {
            id: 3,
            eventimage: "https://images.unsplash.com/photo-1569863959165-56dae551d4fc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGV2ZW50c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60",
            eventname: "Charity Events",
            eventDescription: " This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels,if you like.",
            address: "Prof.Ramkrishna More A.C.S College Akurdi Pune",
            startDate: '2024-10-22T14:00:00',
            endDate: '2024-10-24T10:00:00',
            price: 200
        },

    ];