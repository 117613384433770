import React, { useState, useEffect } from "react";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import {
  Typography, IconButton, Grid, Avatar, Dialog, DialogTitle,
  Button, DialogContent, DialogActions, Slide, Chip
} from '@mui/material';
import "./showinterested.css";
import { BsChat } from "react-icons/bs";
import PhoneIcon from '@mui/icons-material/Phone';
import useAPIRequest from "../../API/useApiRequest";
import { useTheme } from '@mui/material/styles';
import { useToasts } from "react-toast-notifications";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import Constants from "../../MeddyConnectConstant";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import UrlRoute from "../../API/UrlRoute";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShowInterestedList = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();
  const { interestId } = useParams();

  const [interestedUserList, setInterestedUserList] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [interestedUserContactNumber, setInterestedUserContactNumber] = useState("");

  // Services
  const { apiRequests } = useAPIRequest();

  // Dialogs
  const [openAlertVerificationDialog, setOpenAlertVerificationDialog] = React.useState(false);
  const [isInterestedUserCallDetailsDialogOpen, setIsInterestedUserCallDetailsDialogOpen] = useState(false);

  const handleAlertVerificationDialogClickOpen = () => {
    setOpenAlertVerificationDialog(true);
  };

  const handleAlertVerificationDialogClickClose = () => {
    setOpenAlertVerificationDialog(false);
  };

  const handleInterestedUserCallDetailsDialogClickOpen = () => {
    setIsInterestedUserCallDetailsDialogOpen(true);
  };

  const handleInterestedUserCallDetailsDialogClickClose = () => {
    setIsInterestedUserCallDetailsDialogOpen(false);
  };

  const handleVerifyClick = () => {
    history.push(Constants.VERIFICATION);
  };

  useEffect(() => {
    getInterestedUserList(interestId, pageNo);
  }, [])


  const getInterestedUserList = async (interestId, pageNo) => {
    setLoading(true)
    const { response, data, error } = await apiRequests({
      endPoint: `items/interest/${interestId}?page=${pageNo}`,
      method: "GET",
      addToast,
    });

    if (data) {
      setInterestedUserList((prevData) => {
        const uniqueEntities = data.entities.filter(newEntity =>
          !prevData.some(existingEntity => existingEntity.id === newEntity.id)
        );
        return [...prevData, ...uniqueEntities];
      });

      setTotalPages(data.totalPages);
      setPageNo(data.page);
      setLoading(false);
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  };

  const getInterestedUserCallDetails = async (itemId, userId) => {
    setLoading(true)
    const { response, data, error } = await apiRequests({
      endPoint: `${UrlRoute.GET_INTERESTED_USER_CALL_DETAILS_URL}/${itemId}/${userId}`,
      method: "GET",
      addToast,
    });

    if (data) {
      setInterestedUserContactNumber(data.response);
      setLoading(false);
      handleInterestedUserCallDetailsDialogClickOpen();
    } else if (response.status === 403) {
      setLoading(false);
      handleAlertVerificationDialogClickOpen();
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  };

  return (
    <>
      <div style={{ height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="show-interested-drawer">
            <Navigation />
          </div>

          <div className="show-interested-container">

            <div style={{ marginTop: "12px" }}>
              <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={interestedUserList.length}
                next={() => {
                  if (pageNo > 1) {
                    getInterestedUserList(interestId, pageNo + 1);
                  }
                }}
                hasMore={!(pageNo + 1 === totalPages)}
                loader={<YLoader loading={loading} />}
                endMessage={<span></span>}
              >
                {interestedUserList.length > 0 ? (

                  interestedUserList.map((value, index) => {
                    return (
                      <div className="interested-user-list" key={value.id} style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }} onClick={() => getInterestedUserCallDetails(value.itemId, value.userId)} >
                        <Avatar src={value.profileLink ? value.profileLink.href : " "} className='interested-user-picture' />
                        <div className="interested-user-info">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography className='interested-user-Name interested-user_title' align="left" color="text.primary">
                              {value.name}
                            </Typography>
                            {value.userType === "VENDOR" && (
                              <Chip label="Distributor" color="primary" variant="outlined" style={{ marginLeft: '8px' }} />
                            )}
                          </div>
                          <Typography className='interested-user_subtitle1' align="left" color="text.primary">
                            {value.created ? moment(value.created).format("D-MMM-YYYY") : "N/A"}
                          </Typography>
                        </div>

                        <IconButton className="call-button" disableRipple>
                          <PhoneIcon color="primary" />
                        </IconButton>

                      </div>
                    );
                  })

                ) : (
                  <Grid container style={{ marginTop: "10px" }}>
                    <Grid item xs={12}>
                      <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                        No results found
                      </Typography>
                    </Grid>
                  </Grid>
                )}

              </InfiniteScroll>

              <Dialog open={openAlertVerificationDialog} onClose={handleAlertVerificationDialogClickClose}>
                <DialogTitle >MeddyConnect</DialogTitle>
                <DialogContent>

                  <Typography variant="subtitle1" color="text.primary" align="left">
                    You cannot contact seller as you are not a verified user
                  </Typography>

                </DialogContent>
                <DialogActions>
                  <Button onClick={handleAlertVerificationDialogClickClose} color="primary" >Close</Button>
                  <Button onClick={handleVerifyClick} color="primary" >Verify</Button>
                </DialogActions>
              </Dialog>

              <Dialog open={isInterestedUserCallDetailsDialogOpen} onClose={handleInterestedUserCallDetailsDialogClickClose}
                maxWidth="sm" fullWidth TransitionComponent={Transition}
              >
                <DialogTitle >Interested User Call Details</DialogTitle>
                <DialogContent>

                  <Typography variant="subtitle1" color="text.primary" align="left">
                    <span className='bold-text'> Contact No : </span> <a href={`tel:${interestedUserContactNumber}`} style={{ color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1", textDecoration: "none" }}>{interestedUserContactNumber}</a>
                  </Typography>

                </DialogContent>
                <DialogActions>
                  <Button onClick={handleInterestedUserCallDetailsDialogClickClose} color="primary" >Close</Button>
                </DialogActions>
              </Dialog>

            </div>

          </div>
        </div>

        <YLoader loading={loading} />
      </div>
    </>
  )
}

export default ShowInterestedList


