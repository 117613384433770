import React, { useContext, useEffect, useState } from "react";
import {
  CssBaseline, ListItemButton, List, Toolbar,
  ListItem, ListItemIcon, ListItemText, Drawer, Avatar,
  AppBar, Button, IconButton, Typography, Divider, Grid,
  useScrollTrigger,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PersonIcon from '@mui/icons-material/Person';
import ChatIcon from '@mui/icons-material/Chat';
import PolicyIcon from '@mui/icons-material/Policy';
import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import GroupsIcon from '@mui/icons-material/Groups';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import MenuIcon from '@mui/icons-material/Menu';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { BsBoxSeam } from "react-icons/bs";
import {
  BrowserRouter as Router,
  Link,
  useHistory,
} from "react-router-dom";
import { YContext } from "../Context/YContext";
import { images } from "../assets/images/images";
import PropTypes from "prop-types";
import { useTheme } from '@mui/material/styles';
import "./Navigation.css";
import Constants from "../MeddyConnectConstant";
import useMediaQuery from '@mui/material/useMediaQuery';

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Navigation(props) {
  const theme = useTheme();
  const [width, setWidth] = useState(window.innerWidth);
  const [mobileOpen, setMobileOpen] = useState(false);
  const { currentUser, setCurrentUser, setIsAuthenticated } = useContext(YContext);
  const history = useHistory();
  const breakPoint = 960;

  const isDarkMode = theme.palette.mode === 'dark';

  // Use the appropriate logo based on the theme
  const logoSrc = isDarkMode ? images.meddyconnectDarkThemeLogo : images.meddyconnectLightThemeLogo;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const logout = () => {
    localStorage.clear();
    setCurrentUser({});
    setIsAuthenticated(false);
    history.push("/login");
  };

  const checkCurrentUserType = () => {
    if (currentUser.userType === "DOCTOR") {
      history.push("/doctorprofile");
    } else if (currentUser.userType === "HOSPITAL") {
      history.push("/hospitalprofile");
    } else if (currentUser.userType === "VENDOR") {
      history.push("/vendorprofile");
    }
    else if (currentUser.userType === "STUDENT") {
      history.push("/studentprofile");
    }
    else if (currentUser.userType === "STAFF") {
      history.push("/medicalstaffprofile");
    }
    else if (currentUser.userType === "MEDICAL_ASSOCIATION") {
      history.push("/medicalassociationprofile");
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const MobileComponent = () => (
    <ElevationScroll {...props}>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000000",
          color: "black",
          border: theme.palette.mode === "light" ? "1px solid #002E6E" : "1px solid #00B9F1",
        }}
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            style={{ mr: theme.spacing(1), display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={logoSrc}
            alt="meddyconnect"
            className="app-logo"
          />
          <div className="navbar-link">
            <Link
              to={Constants.HOME_SCREEN}
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              <Button color="primary">Home</Button>
            </Link>
            <Link
              to="/storyboard"
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              <Button color="primary">Community</Button>
            </Link>
            {/* {(currentUser.userType === "DOCTOR" || currentUser.userType === "HOSPITAL") ?
              <Link
                to=""
                style={{ textDecoration: "none", fontWeight: "bold" }}
              >
                <Button color="primary">Search</Button>
              </Link>
              :
              ""} */}
            {/* {currentUser.userType === "VENDOR" ?
              <Link
                to=""
                style={{ textDecoration: "none", fontWeight: "bold" }}
              >
                <Button color="primary">Dashboard</Button>
              </Link>
              :
              ""} */}
            <Link
              to="/comingsoon"
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              <Button color="primary">Analytics</Button>
            </Link>
            <Link
              to={Constants.NOTIFICATION_SCREEN}
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              <Button color="primary">Notification</Button>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );

  const DesktopComponent = () => (
    <div className="desktop">
      <ElevationScroll {...props}>
        <AppBar
          position="fixed"
          style={{
            backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000000",
            color: "black",
            border: theme.palette.mode === "light" ? "1px solid #002E6E" : "1px solid #00B9F1"
          }}
        >
          <Toolbar>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              style={{ mr: theme.spacing(1), display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            {/* <img src={images.meddyConnect} alt="meddyconnect" className={classes.logo} /> */}
          </Toolbar>
        </AppBar>
      </ElevationScroll>

      <br></br>

      <AppBar position="fixed" style={{ top: "auto", bottom: 0, backgroundColor: "#002E6E", color: "black" }}>
        <Grid container style={{ justifyContent: "space-around", alignItems: "center", padding: "10px 10px" }} direction="row">
          <Grid item>
            <IconButton>
              <Link
                to={Constants.HOME_SCREEN}
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <HomeIcon />
              </Link>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton>
              <Link
                to="/storyboard"
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <GroupsIcon />
              </Link>
            </IconButton>
          </Grid>
          {/* {(currentUser.userType === "DOCTOR" || currentUser.userType === "HOSPITAL") ?
            <Grid item>
              <IconButton>
                <Link
                  to=""
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  <SearchIcon />
                </Link>
              </IconButton>
            </Grid>
            :
            ""} */}
          {/* {currentUser.userType === "VENDOR" ?
            <Grid item>
              <IconButton>
                <Link
                  to=""
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  <BarChartIcon />
                </Link>
              </IconButton>
            </Grid>
            :
            ""} */}
          <Grid item>
            <IconButton>
              <Link
                to="/comingsoon"
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <EqualizerIcon />
              </Link>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton>
              <Link
                to={Constants.NOTIFICATION_SCREEN}
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <NotificationsActiveIcon />
              </Link>
            </IconButton>
          </Grid>
        </Grid>
      </AppBar>
    </div>

  );

  function NavDrawer() {

    const theme = useTheme();
    const { currentUser } = useContext(YContext);

    const drawer = (
      <Grid style={{ backgroundColor: "background.default", color: "text.primary", height: "100%" }}>
        <Grid container style={{ alignItems: "center", alignSelf: "center", overflow: "hidden", padding: "4px 0" }}>
          <Avatar
            style={{ height: 40, width: 40, margin: "9px 15px" }}
            src={currentUser.profileLink ? currentUser.profileLink.href : " "}
          />
          <Typography
            component="h4"
            variant="h6"
            color="text.primary"
            style={{
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "bold",
              maxWidth: "180px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {currentUser.name}
          </Typography>
        </Grid>
        <Divider color="divider" />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={checkCurrentUserType} style={{ fontSize: "3rem", color: theme.palette.mode === "light" ? "#000000" : "#fff" }}>
              <ListItemIcon>
                <PersonIcon color="primary" className="drawer_icon" />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItemButton>
          </ListItem>
          <ListItem style={{ fontSize: "3rem", color: theme.palette.mode === "light" ? "#000000" : "#fff", textDecoration: "none" }} disablePadding component={Link} to="/comingsoon">
            <ListItemButton>
              <ListItemIcon>
                <ChatIcon color="primary" className="drawer_icon" />
              </ListItemIcon>
              <ListItemText primary="Chat" />
            </ListItemButton>
          </ListItem>
          <ListItem style={{ fontSize: "3rem", color: theme.palette.mode === "light" ? "#000000" : "#fff", textDecoration: "none" }} disablePadding component={Link} to={Constants.VERIFICATION}>
            <ListItemButton>
              <ListItemIcon>
                <VerifiedUserIcon color="primary" className="drawer_icon" />
              </ListItemIcon>
              <ListItemText primary="Verification" />
            </ListItemButton>
          </ListItem>
          <ListItem style={{ fontSize: "3rem", color: theme.palette.mode === "light" ? "#000000" : "#fff", textDecoration: "none" }} disablePadding component={Link} to={Constants.SUBSCRIBE_PACKAGE_DETAILS}>
            <ListItemButton>
              <ListItemIcon>
                <BsBoxSeam className="drawer-react-icon" style={{ color: theme.palette.primary.main }} />
              </ListItemIcon>
              <ListItemText primary="MeddyConnect Package" />
            </ListItemButton>
          </ListItem>
          {(currentUser.userType === "DOCTOR" || currentUser.userType === "HOSPITAL" || currentUser.userType === "VENDOR") ?
            <ListItem style={{ fontSize: "3rem", color: theme.palette.mode === "light" ? "#000000" : "#fff", textDecoration: "none" }} disablePadding component={Link} to={Constants.INVENTORY}>
              <ListItemButton>
                <ListItemIcon>
                  <SettingsIcon color="primary" className="drawer_icon" />
                </ListItemIcon>
                <ListItemText primary="Manage Products" />
              </ListItemButton>
            </ListItem>
            :
            ""}
          {/* <Link
            to=""
            style={{ textDecoration: "none", color: theme.palette.mode === "light" ? "#000000" : "#fff" }}
          >
            <ListItem style={{ fontSize: "3rem" }} button>
              <ListItemIcon>
                <SettingsIcon color="primary" className="drawer_icon" />
              </ListItemIcon>
              <ListItemText primary="Manage Advertisement" />
            </ListItem>
          </Link> */}
          {(currentUser.userType === "DOCTOR" || currentUser.userType === "HOSPITAL") ?
            <ListItem style={{ fontSize: "3rem", color: theme.palette.mode === "light" ? "#000000" : "#fff", textDecoration: "none" }} disablePadding component={Link} to={Constants.MANAGE_JOB_APPLICATIONS}>
              <ListItemButton>
                <ListItemIcon>
                  <SettingsIcon color="primary" className="drawer_icon" />
                </ListItemIcon>
                <ListItemText primary="Manage Job" />
              </ListItemButton>
            </ListItem>
            :
            ""}
          {(currentUser.userType === "DOCTOR" || currentUser.userType === "HOSPITAL") ?
            <ListItem style={{ fontSize: "3rem", color: theme.palette.mode === "light" ? "#000000" : "#fff", textDecoration: "none" }} disablePadding component={Link} to={Constants.MANAGE_OBSERVERSHIP_APPLICATIONS}>
              <ListItemButton>
                <ListItemIcon>
                  <SettingsIcon color="primary" className="drawer_icon" />
                </ListItemIcon>
                <ListItemText primary="Manage Observership" />
              </ListItemButton>
            </ListItem>
            :
            ""}
          {/* {(currentUser.userType !== "STAFF" || currentUser.userType !== "STUDENT") ?
            <Link
              to="/manageevents"
              style={{ textDecoration: "none", color: theme.palette.mode === "light" ? "#000000" : "#fff" }}
            >
              <ListItem style={{ fontSize: "3rem" }} button>
                <ListItemIcon>
                  <SettingsIcon color="primary" className="drawer_icon" />
                </ListItemIcon>
                <ListItemText primary="Manage Events" />
              </ListItem>
            </Link>
            : ""
          } */}
          {/* {(currentUser.userType !== "STAFF" || currentUser.userType !== "STUDENT") ?
            <Link
              to="/managetraining"
              style={{ textDecoration: "none", color: theme.palette.mode === "light" ? "#000000" : "#fff" }}
            >
              <ListItem style={{ fontSize: "3rem" }} button>
                <ListItemIcon>
                  <SettingsIcon color="primary" className="drawer_icon" />
                </ListItemIcon>
                <ListItemText primary="Manage Trainings" />
              </ListItem>
            </Link>
            : ""
          } */}
          {/* <Link
            to="/managemagazines"
            style={{ textDecoration: "none", color: theme.palette.mode === "light" ? "#000000" : "#fff" }}
          >
            <ListItem style={{ fontSize: "3rem" }} button>
              <ListItemIcon>
                <SettingsIcon color="primary" className="drawer_icon" />
              </ListItemIcon>
              <ListItemText primary="Manage Magazines" />
            </ListItem>
          </Link> */}
          <ListItem style={{ fontSize: "3rem", color: theme.palette.mode === "light" ? "#000000" : "#fff", textDecoration: "none" }} disablePadding component="a" href="https://meddyconnect.com/terms-conditions.html" target="_blank" rel="noreferrer">
            <ListItemButton>
              <ListItemIcon>
                <DescriptionIcon color="primary" className="drawer_icon" />
              </ListItemIcon>
              <ListItemText primary="Terms & Conditions" />
            </ListItemButton>
          </ListItem>
          <ListItem style={{ fontSize: "3rem", color: theme.palette.mode === "light" ? "#000000" : "#fff", textDecoration: "none" }} disablePadding component="a" href="https://meddyconnect.com/privacy-policy.html" target="_blank" rel="noreferrer">
            <ListItemButton>
              <ListItemIcon>
                <PolicyIcon color="primary" className="drawer_icon" />
              </ListItemIcon>
              <ListItemText primary="Privacy Policy" />
            </ListItemButton>
          </ListItem>
          <ListItem style={{ fontSize: "3rem", color: theme.palette.mode === "light" ? "#000000" : "#fff", textDecoration: "none" }} disablePadding component="a" href="https://meddyconnect.com/contactus.html" target="_blank" rel="noreferrer">
            <ListItemButton>
              <ListItemIcon>
                <RecentActorsIcon color="primary" className="drawer_icon" />
              </ListItemIcon>
              <ListItemText primary="Contact Us" />
            </ListItemButton>
          </ListItem>
          <ListItem style={{ fontSize: "3rem", color: theme.palette.mode === "light" ? "#000000" : "#fff", textDecoration: "none" }} disablePadding component="a" href="https://meddyconnect.com/index.html" target="_blank" rel="noreferrer">
            <ListItemButton>
              <ListItemIcon>
                <InfoIcon color="primary" className="drawer_icon" />
              </ListItemIcon>
              <ListItemText primary="About Us" />
            </ListItemButton>
          </ListItem>
          <ListItem style={{ fontSize: "3rem", color: theme.palette.mode === "light" ? "#000000" : "#fff", textDecoration: "none" }} disablePadding component={Link} to={Constants.DEACTIVATE_ACCOUNT}>
            <ListItemButton>
              <ListItemIcon>
                <NoAccountsIcon color="primary" className="drawer_icon" />
              </ListItemIcon>
              <ListItemText primary="Deactivate Account" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={logout} style={{ fontSize: "3rem", color: theme.palette.mode === "light" ? "#000000" : "#fff" }}>
              <ListItemIcon>
                <ExitToAppIcon color="primary" className="drawer_icon" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider color="divider" />
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item>
            <IconButton>
              <a href="https://www.facebook.com/people/MeddyConnect/100086169360766/" target="_blank" rel="noreferrer">
                <FacebookIcon style={{ color: "blue" }} className="drawer_icon" /></a>{" "}
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton>
              <a href="https://www.instagram.com/meddyconnect/" target="_blank" rel="noreferrer">
                <InstagramIcon
                  style={{ color: "rgba( 255, 20, 147, 0.65)" }}
                  className="drawer_icon"
                /></a>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton>
              <a href="https://twitter.com/meddyconnect" target="_blank" rel="noreferrer"><TwitterIcon color="primary" className="drawer_icon" /></a>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton>
              <a href="https://www.youtube.com/channel/UCQs87y_idDVzju1ShFMQdxQ" target="_blank" rel="noreferrer"><YouTubeIcon style={{ color: "red" }} className="drawer_icon" /></a>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
    // const container =
    //   window !== undefined ? () => window().document.body : undefined;
    return (
      <>
        <div>

          <CssBaseline />
          {width > breakPoint ? <MobileComponent /> : <DesktopComponent />}
          <nav aria-label="mailbox folders">

            {width < breakPoint ? (
              <Drawer
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{
                  '& .MuiDrawer-paper': {
                    width: '265.34px',
                    backgroundColor: 'background.default',
                    color: 'text.primary',
                  },
                }}
              >
                {drawer}
              </Drawer>
            ) : (
              <Drawer
                variant="permanent"
                open
                sx={{
                  '& .MuiDrawer-paper': {
                    width: '265.34px',
                    backgroundColor: 'background.default',
                    color: 'text.primary',
                  },
                }}
              >
                {drawer}
              </Drawer>
            )}
          </nav>

        </div>

      </>
    );
  }
  return (
    <>
      <div style={{ fontFamily: "Source Sans Pro, sans-serif" }}>
        {/* <CssBaseline /> */}
        {/* {width > breakPoint ? <MobileComponent /> : <DesktopComponent />} */}
        <NavDrawer />
      </div>
    </>
  );

}

