import { useState, useEffect } from 'react';
import { Grid, Card, InputAdornment, CardActionArea, CardMedia, CardContent, TextField, Typography } from "@mui/material";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { useToasts } from "react-toast-notifications";
import './newproductcards.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ImageNotAvailable from "../../assets/images/imagenotavailable.jpg";
import { useHistory } from "react-router-dom";
import useAPIRequest from "../../API/useApiRequest";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchIcon from '@mui/icons-material/Search';
import Constants from "../../MeddyConnectConstant";

const NewProductCards = (props) => {
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { addToast } = useToasts();
  const history = useHistory();

  const { manufacturerName } = props.location.state;

  const [newProductLaunch, setNewProductLaunch] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  // Services
  const { apiRequests } = useAPIRequest();

  useEffect(() => {
    getNewProductLaunch(searchTerm, manufacturerName, pageNo);
  }, [pageNo]);

  const getNewProductLaunch = async (searchTerm, manufacturerName, pageNo) => {

    setLoading(true);
    const { response, data, error } = await apiRequests({
      endPoint: `search/product-launch?q=${searchTerm}&name=${manufacturerName}&page=${pageNo}`,
      method: "GET",
      addToast,
    });

    if (data) {
      setNewProductLaunch((prevData) => {
        const uniqueEntities = data.entities.filter(newEntity =>
          !prevData.some(existingEntity => existingEntity.id === newEntity.id)
        );
        return [...prevData, ...uniqueEntities];
      });
      setTotalPages(data.totalPages);
      setPageNo(data.page);
      setLoading(false);
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  };

  const handleCardClick = (id) => {
    history.push(`${Constants.NEW_PRODUCT_LAUNCH_DETAILS}/${id}`);
  };

  // handle search related functions

  const handleSearchResultChange = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm.length === 0 || searchTerm.length >= 3) {
      setNewProductLaunch([]);
      setPageNo(0);
      getNewProductLaunch(searchTerm, manufacturerName, 0);
    }
  };

  return (
    <>
      <div style={{ height: "auto" }}>

        <div className="launchproduct-filter-container" style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000" }}>
          <div className="launchproduct-search-container" >
            <div className="launchproduct-search-filter-div" >

              <div className="launchproduct-searchbar-textfield">
                <TextField fullWidth
                  placeholder="Search Product..."
                  name="searchbar"
                  id="searchbar"
                  onChange={(event) => handleSearchResultChange(event.target.value)}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end" >
                        < SearchIcon color="primary" />
                      </InputAdornment>,
                  }}
                  className='launchproduct-search-textfield'
                />
              </div>

              {/* <div className='icon-div'>
                <IconButton onClick={handleFilterDialogClickOpen}>
                  <TuneOutlinedIcon className='buynew-filter-icon' color="primary" />
                </IconButton>
              </div> */}

            </div>
          </div>
        </div>

        <div style={{ display: "flex" }}>

          <div className="newproductcards--Drawer">
            <Navigation />
          </div>

          <div className="newproductcards-container">

            <div style={{ marginTop: "12px" }}>
              <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={newProductLaunch.length}
                next={() => {
                  getNewProductLaunch(searchTerm, manufacturerName, pageNo + 1);
                }}
                hasMore={!(pageNo + 1 === totalPages)}
                loader={<YLoader loading={loading} />}
                endMessage={<span></span>}
              >
                <Grid container direction="row" spacing={2}>
                  {newProductLaunch.length > 0 ?
                    newProductLaunch.map((value, index) => {
                      return (
                        <Grid item xs={12} sm={6} key={value.id}>
                          <Card className='newproduct-card'>
                            <CardActionArea onClick={() => { handleCardClick(value.id) }} >
                              {value.imageLink ?
                                <CardMedia
                                  component="img"
                                  height="180"
                                  image={value.imageLink.href}
                                  style={{ objectFit: "fill" }}
                                  alt="hosital equipment"
                                /> :
                                <CardMedia
                                  component="img"
                                  height="180"
                                  image={ImageNotAvailable}
                                  style={{ objectFit: "fill" }}
                                  alt="hosital equipment"
                                />
                              }
                              <CardContent>
                                <Grid container direction="row" alignItems="center">
                                  <Grid item xs={12}>
                                    <Typography variant="h6" align="left" color="text.primary" className="newproduct-text-capital newproduct-title newproduct-text">
                                      {value.title}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="subtitle1" color="text.primary" align="left" className="newproduct-text-capital">
                                      {value.brand}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" color="text.primary" className="newproduct-details newproduct-text-capital" >
                                      {value.description}
                                    </Typography>
                                  </Grid>
                                </Grid>

                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      );
                    })
                    :
                    <Grid container style={{ marginTop: "10px" }}>
                      <Grid item xs={12}>
                        <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                          No results found
                        </Typography>
                      </Grid>
                    </Grid>
                  }

                </Grid>
              </InfiniteScroll>

            </div>

          </div>
        </div>
        <YLoader loading={loading} />
      </div>

    </>
  )
}

export default NewProductCards
