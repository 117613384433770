import { useEffect } from 'react';
import { onMessage } from "firebase/messaging";
import { messaging } from '../../firebase';

function useFirebaseMessaging() {

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Received foreground message ", payload);

      const { title, body } = payload.data;
      let { link } = payload.data;

      // Convert the custom scheme URL to a web URL
      if (link && link.startsWith('meddyconnect://')) {
        try {
          link = convertCustomSchemeToWebURL(link);
          console.log("Converted URL:", link);
        } catch (error) {
          console.error("Error converting custom scheme URL:", error);
          link = null; // Clear the link if there's an error
        }
      }

      // Use the Web Notifications API for system notifications
      if (Notification.permission === "granted") {
        const notification = new Notification(title, {
          body: body,
          icon: payload.data.image,
          data: { link },
        });

        // Handle notification click
        notification.onclick = (event) => {
          event.preventDefault(); // Prevent the default action

          if (link) {
            const targetURL = new URL(link, window.location.origin).href;
            const currentURL = window.location.href;// Current page URL
            console.log("Current URL:", currentURL);
            console.log("Target URL:", targetURL);

            if (currentURL === targetURL) {
              console.log("User is already on the target page:");
            } else {
              if (window.location.pathname === "/login") {
                // Save the link for after login
                localStorage.setItem("notificationRedirectURL", link);
              } else {
                // Redirect immediately for other pages
                console.log("Link before opening:", link);
                // window.open(link, '_blank');
                window.location.href = link; // Open in the current tab
              }
            }
          } else {
            // Handle the case where the link is missing or invalid
            console.warn("Notification clicked, but no valid link provided.");
          }

        };

      } else {
        console.warn("Notifications are not permitted by the user");
      }

    });

    return () => unsubscribe();
  }, []);

  // Function to convert custom scheme URLs to web URLs

  function convertCustomSchemeToWebURL(customSchemeURL) {
    const expectedScheme = 'meddyconnect://';

    if (customSchemeURL.startsWith(expectedScheme)) {
      // Remove the scheme part to get the remaining part of the URL
      const remainingURL = customSchemeURL.slice(expectedScheme.length);

      // Split the remaining URL into path and query parts
      const [path, query] = remainingURL.split('?');

      // Construct the base URL
      // const baseURL = 'https://app.meddyconnect.com';
      const baseURL = 'http://localhost:3000';

      // Construct the full web URL
      // const webURL = query ? `${baseURL}/${path}/${query}` : `${baseURL}/${path}`;
    
      const webURL = query ? `/${path}/${query}` : `/${path}`;
      console.log(webURL)
      return webURL;
    } else {
      throw new Error("Unsupported custom scheme URL.");
    }
  }

}

export default useFirebaseMessaging;
