import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, IconButton, Slide, Avatar, Typography, Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useToasts } from "react-toast-notifications";
import SendIcon from '@mui/icons-material/Send';
import InfiniteScroll from "react-infinite-scroll-component";
import useAPIRequest from "../../API/useApiRequest";
import UrlRoute from "../../API/UrlRoute";
import CircularProgress from '@mui/material/CircularProgress';
import "./shortsfeed.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CommentDialog = ({ openCommentDialog, handleCloseCommentDialog, selectedVideoId, onCommentAdded }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [commentLoading, setCommentLoading] = useState(false);
    const { addToast } = useToasts();

    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState("");
    const [pageNo, setPageNo] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    // Services
    const { apiRequests } = useAPIRequest();

    useEffect(() => {
        if (openCommentDialog) {
            fetchComments(selectedVideoId, pageNo);
        }
    }, [openCommentDialog]);

    const fetchComments = async (selectedVideoId, pageNo) => {
        setCommentLoading(true);
        try {
            const { response, data, error } = await apiRequests({
                endPoint: `${UrlRoute.GET_COMMENTS_FOR_SHORTS_URL}/${selectedVideoId}?page=${pageNo}`,
                method: "GET",
                addToast,
            });

            if (data) {

                setComments((prevData) => {
                    const uniqueEntities = data.entities.filter(newEntity =>
                        !prevData.some(existingEntity => existingEntity.id === newEntity.id)
                    );
                    return [...uniqueEntities, ...prevData];
                });

                setTotalPages(data.totalPages);
                setPageNo(data.page);

            } else {
                addToast("Failed to fetch comments!", { appearance: "error" });
            }
        } catch (error) {
            addToast("Error fetching comments:", { appearance: "error" });
        } finally {
            setCommentLoading(false);
        }
    };

    const handleCommentChange = (event) => {
        setNewComment(event.target.value);
    };

    const handleAddComment = async () => {
        if (!newComment.trim()) return;

        try {
            const { response } = await apiRequests({
                endPoint: `${UrlRoute.ADD_COMMENT_URL}/${selectedVideoId}`,
                method: "POST",
                body: { message: newComment },
                addToast,
            });

            if (response.status === 200) {
                setNewComment("");
                setPageNo(0);
                fetchComments(selectedVideoId, 0);

                if (onCommentAdded) {
                    onCommentAdded(selectedVideoId);
                }

                addToast("Comment added successfully", { appearance: "success" });
            } else {
                addToast("Failed to add comment", { appearance: "error" });
            }
        } catch (error) {
            addToast("Error adding comment", { appearance: "error" });
        }
    };

    return (
        <>
            <Dialog
                open={openCommentDialog}
                onClose={handleCloseCommentDialog}
                fullScreen={fullScreen}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
            >
                <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                    <IconButton
                        edge="start"
                        onClick={handleCloseCommentDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon style={{ color: "white" }} />
                    </IconButton>
                    Comments
                </DialogTitle>
                <DialogContent style={{ padding: "0px 20px 20px 20px", display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div className="scrollable-comments-container">
                        <InfiniteScroll
                            style={{ overflow: "hidden" }}
                            dataLength={comments.length}
                            next={() => {
                                fetchComments(selectedVideoId, pageNo + 1);
                            }}
                            hasMore={!(pageNo + 1 === totalPages)}
                            loader={
                                commentLoading && (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                                        <CircularProgress />
                                    </Box>
                                )
                            }
                            endMessage={<span></span>}
                        >
                            {comments.length === 0 ? (
                                <Typography align="center" color="text.primary" variant="subtitle1" style={{ marginTop: "20px" }}>
                                    No comments yet. Be the first to share your thoughts!
                                </Typography>
                            ) : (
                                <div className="comment-list">
                                    {comments.map((comment) => (
                                        <div key={comment.id} className="comment-item">
                                            <Avatar src={comment.profile && comment.profile.href ? comment.profile.href : " "} className="comment-avatar" />
                                            <div className="comment-content">
                                                <Typography variant="subtitle2" color="text.primary" gutterBottom>{comment.userName}</Typography>
                                                <Typography variant="body2" color="text.primary">{comment.message}</Typography>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </InfiniteScroll>
                    </div>

                    <div className="comment-input-container">
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Add a comment"
                            value={newComment}
                            onChange={handleCommentChange}
                            multiline
                            rows={2}
                            className="comment-textfield"
                        />
                        <IconButton className='comment-button'
                            style={{
                                backgroundColor: newComment.trim()
                                    ? (theme.palette.mode === "light" ? "#002E6E" : "#00B9F1")
                                    : "#535659",
                            }}
                            onClick={handleAddComment}
                            disabled={!newComment.trim()}
                        >
                            <SendIcon />
                        </IconButton>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CommentDialog
