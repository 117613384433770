import React from 'react'
import { useEffect, useState, useRef, useContext } from "react";
import { useParams } from 'react-router-dom';
import { useSwipeable } from "react-swipeable";
import { IconButton, Avatar, Typography, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Slide, Button } from "@mui/material";
import Navigation from "../../Navigation/Navigation";
import { YContext } from "../../Context/YContext";
import YLoader from "../../components/Loader";
import ShortsFeed from './ShortsFeed';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import FlagIcon from '@mui/icons-material/Flag';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import userPublicAPIRequest from "../../API/useApiPublicRequest"
import UrlRoute from "../../API/UrlRoute";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import "./shortpage.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShortPage = () => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const { shortId } = useParams();
  const history = useHistory();

  const [shortData, setShortData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [showControls, setShowControls] = useState(false);
  const [expandedCaption, setExpandedCaption] = useState(false);
  const showMore = shortData && shortData.caption && shortData.caption.length > 70;
  const videoRef = useRef(null);

  // Services
  const { apiPublicRequests } = userPublicAPIRequest();
  const { isAuthenticated } = useContext(YContext);

  // Dialogs
  const [openLoginDialog, setOpenLoginDialog] = useState(false);

  const handleLoginDialogClickOpen = () => {
    setOpenLoginDialog(true);
  };

  const handleLoginDialogClickClose = () => {
    setOpenLoginDialog(false);
  };

  useEffect(() => {
    fetchSingleShort();
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying]);

  const fetchSingleShort = async () => {
    setLoading(true);
    try {
      const { response, data, error } = await apiPublicRequests({
        endPoint: `${UrlRoute.GET_SINGLE_SHORT_URL}/${shortId}`,
        method: "GET",
        addToast,
      });

      if (data) {
        console.log(data)
        setShortData(data);

      } else {
        addToast("Something went wrong!", { appearance: "error" });
      }
    } catch (error) {
      addToast("Error fetching short:", { appearance: "error" });
    } finally {
      setLoading(false);
    }
  };

  // Swipe handlers for showing the login dialog
  const swipeHandlers = useSwipeable({
    onSwipedUp: () => {
      if (!isAuthenticated) {
        handleLoginDialogClickOpen();
      }
    },
    onSwipedDown: () => {
      if (!isAuthenticated) {
        handleLoginDialogClickOpen();
      }
    },
    delta: 10, // minimum distance for a swipe
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  }); 

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleVideoClick = () => {
    togglePlayPause();
    setShowControls(true);
    setTimeout(() => setShowControls(false), 2000); // Hide controls after 2 seconds
  };

  const handleCaptionToggle = () => {
    setExpandedCaption(!expandedCaption);
  };

  const handleInteraction = () => {
    if (!isAuthenticated) {
      handleLoginDialogClickOpen();
    }
  };

  const handleLoginRedirect = () => {
    history.push("/login");
    handleLoginDialogClickClose();
  };

   console.log(isAuthenticated)
  return (
    <>
    {!isAuthenticated ? (
    <div {...swipeHandlers} style={{ height: "100vh", overflow: "hidden" }}>

      <div style={{ display: "flex" }}>

        <div className="shortpage-drawer">
          <Navigation />
        </div>

        <div className="shortpage-container">

          {shortData ? (
            <div className="short-item">
              <video
                ref={videoRef}
                className="shortpage-video"
                src={shortData.shortLink.href}
                controls={false}
                autoPlay
                loop
                playsInline
                muted={!isPlaying}
                onClick={handleVideoClick}
              ></video>
              {showControls && (
                <div className="short-controls">
                  <IconButton
                    className="short-control-button"
                    onClick={togglePlayPause}>
                    {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                  </IconButton>
                </div>
              )}

              {/* Action Buttons */}
              <div className="short-actions">

                <div className="short-action-item">
                  <IconButton className="short-buttons" onClick={handleInteraction}>
                    <FavoriteIcon />
                  </IconButton>
                  <Typography variant="body2" color="text.primary">{shortData.noOfLikes}</Typography>
                </div>
                <div className="short-action-item">
                  <IconButton className="short-buttons" onClick={handleInteraction}>
                    <CommentIcon />
                  </IconButton>
                  <Typography variant="body2" color="text.primary">{shortData.noOfMessages}</Typography>
                </div>
                <div className="short-action-item">
                  <IconButton className="short-buttons" onClick={handleInteraction}>
                    <ShareIcon />
                  </IconButton>
                </div>

              </div>

              <div className="short-report-container">
                <IconButton className="short-report-button" onClick={handleInteraction}>
                  <FlagIcon />
                </IconButton>
              </div>

              {/* Caption */}
              <div className="short-info">
                <Avatar src={shortData.profileLink?.href} className="short-user-avatar" />
                <div className="short-caption-container">
                  <div className={`short-caption ${expandedCaption ? "expanded" : ""}`}>

                    <Typography variant="subtitle2" color="text.primary">{shortData.userName}</Typography>
                    {shortData.caption && shortData.caption.trim() !== "" && (
                      <Typography variant="body2" color="text.primary">{shortData.caption}</Typography>
                    )}

                  </div>
                  {showMore && (
                    <IconButton
                      onClick={handleCaptionToggle}
                      className="short-expand-button"
                    >
                      {expandedCaption ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <Grid container style={{ marginTop: "10px" }}>
              <Grid item xs={12}>
                <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                  No results found
                </Typography>
              </Grid>
            </Grid>
          )
          }

          <Dialog
            open={openLoginDialog}
            onClose={handleLoginDialogClickClose}
            TransitionComponent={Transition}
          >
            <DialogTitle >Login Required</DialogTitle>
            <DialogContent>

              <Typography variant="subtitle1" color="text.primary" align="left">
              You need to log in to like, comment, share, or report this video.
              </Typography>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleLoginDialogClickClose} color="primary" >Cancel</Button>
              <Button onClick={handleLoginRedirect} color="primary" >Log In</Button>
            </DialogActions>
          </Dialog>

        </div>
      </div>

      <YLoader loading={loading} />
    </div>
     ) : (
      // Authenticated users see the full feed
      <ShortsFeed />
    )}
    </>

  )
}

export default ShortPage

