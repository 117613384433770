import { useState, useRef, useEffect, useContext } from 'react';
import { Button, TextField, IconButton, Grid, Paper, Slider, Typography } from '@mui/material';
import { VideoLibrary, Videocam } from '@mui/icons-material';
import VideocamIcon from '@mui/icons-material/Videocam';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { YContext } from "../../Context/YContext";
import UrlRoute from "../../API/UrlRoute";
import useAPIRequest from "../../API/useApiRequest";
import { useToasts } from "react-toast-notifications";
import "./uploadshort.css";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { signInWithCustomToken } from "firebase/auth";
import { storage, firebaseAuth } from '../../../firebase';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import { useHistory } from "react-router-dom";
import Constants from "../../MeddyConnectConstant";
import ClearIcon from '@mui/icons-material/Clear';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const UploadShort = () => {
    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [videoFile, setVideoFile] = useState(null);
    const [trimmedFile, setTrimmedFile] = useState(null);
    const [videoURL, setVideoURL] = useState("");
    const [caption, setCaption] = useState("");
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [showVideo, setShowVideo] = useState(false);
    const { addToast } = useToasts();
    const [loaded, setLoaded] = useState(false);
    const ffmpegRef = useRef(new FFmpeg());
    const [timeRange, setTimeRange] = useState([0, 60]);
    const [duration, setDuration] = useState(0);
    const [isTrimmedAndCompressed, setIsTrimmedAndCompressed] = useState(false);
    const history = useHistory();

    // Services
    const { isProcessOngoing, startProcess, finishProcess } = useContext(YContext);
    const { apiRequests } = useAPIRequest();

    const fileInputRef = useRef(null);
    const videoRef = useRef(null);
    const workerRef = useRef(null);

    useEffect(() => {
        const loadFFmpeg = async () => {
            const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
            const ffmpeg = ffmpegRef.current;
            ffmpeg.on('log', ({ message }) => {
                // console.log(message);
            });
            // toBlobURL is used to bypass CORS issue, urls with the same
            // domain can be used directly.
            await ffmpeg.load({
                coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
                wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
            });
            setLoaded(true);
        }

        loadFFmpeg();
    }, [])

    useEffect(() => {
        if (isProcessOngoing) {
            setShowLoader(true);
        } else {
            setShowLoader(false);
        }
    }, [isProcessOngoing]);

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            const file = e.target.files[0];
            setVideoFile(file);
            const url = URL.createObjectURL(file);
            setVideoURL(url);
            setShowVideo(true);// Show video element when a file is selected
            setIsTrimmedAndCompressed(false); // Reset trimmed and compressed state

            if (videoRef.current) {
                videoRef.current.srcObject = null;
                videoRef.current.src = url;
                videoRef.current.onloadedmetadata = () => {
                    setDuration(videoRef.current?.duration || 0);
                    setTimeRange([0, Math.min(videoRef.current?.duration || 60, 60)]);
                };
            }
        }
    };

    const handleChangeVideo = () => {
        setLoading(true);

        setVideoFile(null);
        setVideoURL("");
        setCaption("");
        setShowVideo(false);// Hide video element when changing video

        if (mediaRecorder) {
            mediaRecorder.stop();
            setIsRecording(false);
            setMediaRecorder(null);
        }

        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }

        if (videoRef.current) {
            videoRef.current.pause(); // Pause the video if it's playing
            videoRef.current.srcObject = null; // Clear srcObject
            videoRef.current.src = ""; // Clear src
        }

        setIsTrimmedAndCompressed(false); // Reset trimmed and compressed state
        setLoading(false);
    };

    const startRecording = async () => {
        setLoading(true);
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                const recorder = new MediaRecorder(stream);
                setMediaRecorder(recorder);

                let chunks = [];
                let startTime;

                recorder.ondataavailable = (e) => {
                    chunks.push(e.data);
                };

                recorder.onstart = () => {
                    startTime = Date.now();  // Record the start time
                };

                recorder.onstop = () => {
                    const blob = new Blob(chunks, { type: 'video/webm' });
                    const url = URL.createObjectURL(blob);
                    setVideoURL(url);
                    setVideoFile(new File([blob], 'recorded-video.webm', { type: 'video/webm' }));
                    chunks = [];  // Reset chunks after creating the blob

                    const stopTime = Date.now();  // Record the stop time
                    const duration = (stopTime - startTime) / 1000;  // Calculate duration in seconds

                    if (videoRef.current) {
                        videoRef.current.srcObject = null;  // Clear srcObject after recording
                        videoRef.current.src = url;         // Set src to the recorded video blob URL
                        videoRef.current.onloadedmetadata = () => {
                            setDuration(duration);
                            setTimeRange([0, Math.min(duration, 60)]);
                        };
                    }

                    recorder.stop();   // Stop the MediaRecorder instance
                    setMediaRecorder(null);
                    setIsRecording(false);
                    setShowVideo(true);// Show video element when recording is complete
                    setIsTrimmedAndCompressed(false); // Reset trimmed and compressed state
                };

                recorder.start();
                setIsRecording(true);
                setShowVideo(true);// Show live feed during recording
            } else {
                console.error("videoRef.current is not set.");
            }
        } catch (err) {
            if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
                addToast("Permission to access camera was denied. Please allow camera access to record video.", { appearance: "error" });
            } else {
                addToast("An error occurred while trying to access the camera.", { appearance: "error" });
            }
            console.error("Error accessing camera: ", err);
        }
        setLoading(false);
    };

    const stopRecording = () => {
        setLoading(true); // Start loading

        if (mediaRecorder) {
            mediaRecorder.stop();

            if (videoRef.current && videoRef.current.srcObject) {
                videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
                videoRef.current.srcObject = null;
            }

            setMediaRecorder(null);
            setIsRecording(false);
        } else {
            console.error('MediaRecorder is not set.');
        }

        setLoading(false); // Stop loading
    };


    // Function to upload video to Firebase Storage
    const uploadVideo = async (videoFile, caption) => {
        try {

            const token = await getAuthToken();

            if (!token) {
                addToast("Failed to retrieve authentication token. Video upload aborted.", { appearance: "error" });
                return;
            }
            console.log(firebaseAuth)
            console.log(token)
            // await signInWithCustomToken(firebaseAuth, token);

            signInWithCustomToken(firebaseAuth, token)
                .then((userCredential) => {
                    const user = userCredential.user;
                    console.log("User signed in with custom token:", user);

                    const storageRef = ref(storage, `com.meddyconnect.shorts/${Date.now()}-${videoFile.name}`);
                    const uploadTask = uploadBytesResumable(storageRef, videoFile);
        
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            // Handle progress
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        
                        },
                        (error) => {
                            addToast("An error occurred while uploading the video. Please try again.", { appearance: "error" });
                        },
                        async () => {
                            try {
                                // Handle successful upload
                                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        
                                await createShorts(downloadURL, caption);
        
                                setVideoURL("");
                                setVideoFile(null);
                                setTrimmedFile(null);
                                setCaption('');
                                setIsTrimmedAndCompressed(false);
                                setShowVideo(false);
                            } catch (error) {
                                if (error.message.includes('Error getting download URL')) {
                                    addToast("An error occurred while getting download URL.", { appearance: "error" });
                                } else {
                                    addToast("An error occurred while saving video metadata.", { appearance: "error" });
                                }
                            }
                        }
                    );
                })
                .catch((error) => console.error(error.message));

            // const storageRef = ref(storage, `com.meddyconnect.shorts/${Date.now()}-${videoFile.name}`);
            // const uploadTask = uploadBytesResumable(storageRef, videoFile);

            // uploadTask.on('state_changed',
            //     (snapshot) => {
            //         // Handle progress
            //         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            //     },
            //     (error) => {
            //         addToast("An error occurred while uploading the video. Please try again.", { appearance: "error" });
            //     },
            //     async () => {
            //         try {
            //             // Handle successful upload
            //             const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            //             await createShorts(downloadURL, caption);

            //             setVideoURL("");
            //             setVideoFile(null);
            //             setTrimmedFile(null);
            //             setCaption('');
            //             setIsTrimmedAndCompressed(false);
            //             setShowVideo(false);
            //         } catch (error) {
            //             if (error.message.includes('Error getting download URL')) {
            //                 addToast("An error occurred while getting download URL.", { appearance: "error" });
            //             } else {
            //                 addToast("An error occurred while saving video metadata.", { appearance: "error" });
            //             }
            //         }
            //     }
            // );

        } catch (error) {
            addToast("Unexpected error. Please try again later.", { appearance: "error" });
        }

    };

    const trimVideo = async () => {
        setLoading(true);
        const ffmpeg = ffmpegRef.current;
        const inputFile = videoFile;

        if (!loaded) {
            setLoading(false);
            return;
        }

        try {
            if (videoRef.current) {
                videoRef.current.pause();
            }

            await ffmpeg.writeFile("input.mp4", await fetchFile(inputFile));
            const [start, end] = timeRange;
            const trimDuration = Math.min(end - start, 60);

            await ffmpeg.exec([
                "-ss", start.toString(),
                "-i", "input.mp4",
                "-t", trimDuration.toString(),
                "-c", "copy",
                "-y",
                "trimmed.mp4"
            ]);

            const trimmedData = await ffmpeg.readFile('trimmed.mp4');
            const trimmedBlob = new Blob([trimmedData.buffer], { type: 'video/mp4' });
            const trimmedUrl = URL.createObjectURL(trimmedBlob);

            setVideoURL(trimmedUrl);
            setTrimmedFile(new File([trimmedBlob], 'trimmed-video.mp4', { type: 'video/mp4' }));
            setIsTrimmedAndCompressed(true);

        } catch (error) {
            console.error("Error during trimming:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        workerRef.current = new Worker(new URL('./compressWorker.js', import.meta.url));
        workerRef.current.onmessage = handleWorkerMessage;
    }, []);

    const handleWorkerMessage = (e) => {
        const { success, compressedFile, caption, error } = e.data;

        if (success) {
            setVideoFile(compressedFile);
            uploadVideo(compressedFile, caption);
        } else {
            addToast(`Compression error: ${error}`, { appearance: "error" });
        }

        // Terminate the worker after compression is done
        if (workerRef.current) {
            workerRef.current.terminate();
            workerRef.current = null; // Clean up reference
        }
    };

    const handleUpload = async (trimmedFile, caption) => {

        if (videoRef.current) {
            videoRef.current.pause();
        }

        if (trimmedFile) {

            addToast("Upload started.", { appearance: "info" });
            history.push(Constants.HOME_SCREEN);

            // Start compression in the worker
            workerRef.current.postMessage({ trimmedFile: trimmedFile, caption: caption });
            startProcess();
        } else {
            addToast("Please complete all fields.", { appearance: "error" });
        }
    };

    const createShorts = async (videoURL, caption) => {

        try {
            const { response, data, error } = await apiRequests({
                endPoint: UrlRoute.CREATE_SHORTS_URL,
                method: "POST",
                body: {
                    shortLink: videoURL,
                    caption: caption,
                },
                addToast,
            });

            if (data) {
                addToast("Video uploaded successfully!", { appearance: "success" });
            } else {
                addToast("Something went wrong!", { appearance: "error" });
            }
            finishProcess();
        } catch (error) {
            addToast("An error occurred during the API call.", { appearance: "error" });
            finishProcess();
        }
    };

    const getAuthToken = async () => {
        setLoading(true);
        try {
            const { response, data, error } = await apiRequests({
                endPoint: UrlRoute.FIREBASE_AUTH_TOKEN_URL,
                method: "GET",
                addToast,
            });

            if (data) {
                return data;
            } else {
                addToast("Failed to fetch auth token.", { appearance: "error" });
                return null;
            }
        } catch (error) {
            addToast("Something went wrong!", { appearance: "error" });
            return null;
        } finally {
            setLoading(false);
        }

    };


    return (
        <div style={{ height: "auto" }}>

            <div style={{ display: "flex" }}>

                <div className="video-upload-drawer">
                    <Navigation />
                </div>

                <div className="video-upload-container" style={{ position: "relative" }}>

                    {showLoader ? (

                        <Backdrop open={showLoader} sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1301 }}>
                            <CircularProgress color="primary" />
                        </Backdrop>

                    ) : (
                        <>
                            <Paper elevation={2} className="video-upload-paper-container">

                                <div className="media-container">

                                    {showVideo && (
                                        <div className="video-overlay">
                                            <IconButton
                                                className="clear-icon"
                                                aria-label="clear-icon"
                                                onClick={handleChangeVideo}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </div>
                                    )}

                                    {!showVideo && (
                                        <div className="no-video-container">
                                            <VideocamOffIcon color="primary" className="no-video-icon" />
                                            <Typography variant="subtitle1" color="text.primary" align="center">
                                                No Video Selected
                                            </Typography>
                                            <Typography variant="subtitle2" color="text.primary" align="center">
                                                Tap to select a video from your library.
                                            </Typography>
                                        </div>
                                    )}

                                    <video
                                        ref={videoRef}
                                        src={videoURL}
                                        controls
                                        autoPlay
                                        style={{ display: showVideo ? 'block' : 'none' }}
                                    />
                                </div>

                            </Paper>

                            <div className="media-card">

                                {!videoURL && (
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                        <>
                                            <Grid item xs={12} style={{ marginTop: "12px" }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<VideoLibrary />}
                                                    component="label"
                                                    htmlFor="fileInput"
                                                    fullWidth
                                                    className="video-upload-btn"
                                                >
                                                    Add Video
                                                    <input
                                                        id="fileInput"
                                                        ref={fileInputRef}
                                                        type="file"
                                                        accept="video/*"
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange}
                                                    />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: "12px" }}>
                                                {isRecording ? (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<Videocam />}
                                                        onClick={stopRecording}
                                                        fullWidth
                                                        className="video-upload-btn"
                                                    >
                                                        Stop Recording
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<Videocam />}
                                                        onClick={startRecording}
                                                        fullWidth
                                                        className="video-upload-btn"
                                                    >
                                                        Record Video
                                                    </Button>
                                                )}
                                            </Grid>
                                        </>
                                    </Grid>
                                )}

                                {showVideo && !isTrimmedAndCompressed && (
                                    <Grid container direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                                        <Grid item xs={12} style={{ padding: "0px 10px" }}>
                                            <Slider
                                                value={timeRange}
                                                onChange={(event, newValue) => {
                                                    const [start, end] = newValue;
                                                    const newEnd = Math.min(start + 60, duration); // Ensure the range is exactly 1 minute
                                                    setTimeRange([start, newEnd]);
                                                }}
                                                valueLabelDisplay="auto"
                                                min={0}
                                                max={duration}
                                                step={0.1}
                                            />
                                        </Grid>
                                    </Grid>
                                )}

                                {showVideo && !isTrimmedAndCompressed && (
                                    <Grid container direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={trimVideo}
                                                fullWidth
                                                className="video-upload-btn"
                                            >
                                                Continue
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}

                                {isTrimmedAndCompressed && (
                                    <Grid container direction="row" spacing={0} justifyContent="space-between" alignItems="center">
                                        <Grid item xs={12} >
                                            <TextField
                                                label="Write a caption"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={2}
                                                value={caption}
                                                onChange={(e) => setCaption(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: "12px" }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<VideocamIcon />}
                                                onClick={() => handleUpload(trimmedFile, caption)}
                                                fullWidth
                                                className="video-upload-btn"
                                            >
                                                Upload Video
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}

                            </div>
                        </>
                    )}

                </div>
            </div>
            <YLoader loading={loading} />
        </div>
    )
}

export default UploadShort