import React, { useState, useContext, useEffect } from 'react';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import {
  Button, Typography, Dialog, DialogTitle, DialogContent,
  Slide, IconButton, DialogActions, RadioGroup, Radio, FormControlLabel, FormControl
} from '@mui/material';
import { YContext } from "../../Context/YContext";
import useMediaQuery from '@mui/material/useMediaQuery';
import PlaceIcon from '@mui/icons-material/Place';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import { HiOutlineVideoCamera } from "react-icons/hi2";
import { FiExternalLink } from "react-icons/fi";
import { useTheme } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import useAPIRequest from "../../API/useApiRequest";
import { useToasts } from "react-toast-notifications";
import Constants from "../../MeddyConnectConstant";
import UrlRoute from "../../API/UrlRoute";
import "./eventdetails.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EventDetails = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { addToast } = useToasts();
  const history = useHistory();

  const [selectedRole, setSelectedRole] = useState("");

  // Services
  const { apiRequests } = useAPIRequest();

  // Dialogs
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Open dialog
  const handleRegisterClick = () => {
    setIsDialogOpen(true);
  };

  // Close dialog and reset selection
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedRole("");
  };

  // Handle selection
  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleSubmitSelection = () => {

    if (!selectedRole) {
      addToast("Select either Visitor or Exhibitor to proceed with registration.", { appearance: 'error' });
      return;
    }

    if (selectedRole === "visitor") {
      history.push(Constants.VISITOR_REGISTRATION);
    } else if (selectedRole === "exhibitor") {
      // navigate("/exhibitor-page");
    }
    setIsDialogOpen(false);
    setSelectedRole("");
  };

  return (
    <>
      <div style={{ height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="event-details-drawer">
            <Navigation />
          </div>

          <div className="event-details-wrapper">

            <div className="event-details-container">
              <img src={eventDetails.eventImage} alt="Event" className="event-image" />

              <div className="event-info">
                <Typography variant="h6" align="left" color="text.primary" className="event-title">
                  {eventDetails.eventTitle}
                </Typography>

                <div className="event-type">
                  <HiOutlineVideoCamera style={{ fontSize: fullScreen ? "22px" : "24px", verticalAlign: "middle", color: theme.palette.primary.main }} />
                  <Typography variant="subtitle1" align="left" color="text.primary">
                    Online
                  </Typography>
                </div>

                <div className="event-link">
                  <FiExternalLink style={{ fontSize: fullScreen ? "22px" : "24px", verticalAlign: "middle", color: theme.palette.primary.main }} />
                  <a
                    href={eventDetails.eventLink}
                    target="_blank" rel="noreferrer"
                    style={{ textDecoration: "none", color: theme.palette.text.primary }}
                  > {eventDetails.eventLink}
                  </a>
                </div>

                <div className="event-time">
                  <CalendarTodayIcon color="primary" style={{ fontSize: fullScreen ? "20px" : "22px", verticalAlign: "middle", marginTop: "2px" }} />
                  <Typography variant="subtitle1" align="left" color="text.primary">
                    4 October  - 17 November | 11.30 AM -  9.30 PM
                  </Typography>
                </div>

                <div className="event-location">
                  <PlaceIcon color="primary" style={{ fontSize: fullScreen ? "22px" : "24px", verticalAlign: "middle", marginTop: "2px" }} />
                  <Typography variant="subtitle1" align="left" color="text.primary">
                    {eventDetails.eventAddress}
                  </Typography>
                </div>

                <hr className="price-divider" />

                <div className="price-button-wrapper">
                  <div className="price">&#8377; {eventDetails.price}</div>
                  <Button variant="contained" color="primary" className="register-button" onClick={handleRegisterClick}>
                    Register
                  </Button>
                </div>

              </div>

            </div>

            <div className="event-details">

              <Typography variant="subtitle1" align="left" color="text.primary" >
                <span className='event-subtitle'>Event by :</span> {eventDetails.eventHost}
              </Typography>
              <Typography variant="subtitle1" align="left" color="text.primary" >
                <span className='event-subtitle'>Capacity :</span> {eventDetails.eventCapacity}
              </Typography>

              <Typography align="left" color="text.primary" className="event-subtitle">
                About the Event
              </Typography>
              <Typography variant="subtitle1" align="left" color="text.primary" >
                {eventDetails.eventDetails}
              </Typography>

            </div>

            <Dialog
              open={isDialogOpen}
              onClose={handleCloseDialog}
              fullScreen={fullScreen}
              maxWidth="sm"
              fullWidth
              TransitionComponent={Transition}
            >
              <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                <IconButton
                  edge="start"
                  onClick={handleCloseDialog}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
                Register As
              </DialogTitle>
              <DialogContent>
                <FormControl>
                  <RadioGroup
                    name="userrole-radio-buttons"
                    value={selectedRole}
                    onChange={handleRoleChange}
                  >
                    <FormControlLabel value="visitor" control={<Radio />} label="Visitor" />
                    <FormControlLabel value="exhibitor" control={<Radio />} label="Exhibitor" />
                  </RadioGroup>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary" >Cancel</Button>
                <Button onClick={handleSubmitSelection} color="primary" >Submit</Button>
              </DialogActions>
            </Dialog>


          </div>
        </div>
        <YLoader loading={loading} />
      </div>
    </>
  )
}

export default EventDetails

const eventDetails = {
  "eventTitle": "Armaan Malik - Live in Vizag",
  "eventImage": "https://res.cloudinary.com/dwzmsvp7f/image/upload/q_75,f_auto,w_800/c_crop%2Cg_custom%2Fv1727007001%2Fgfeixrfrs5xhkcecafpu.jpg",
  "eventStartDate": "2024-11-10",
  "eventStartTime": "09:00 AM",
  "eventEndDate": "2024-11-10",
  "eventEndTime": "05:00 PM",
  "eventAddress": "Radisson Blu Resort Visakhapatnam, Visakhapatnam",
  "eventDetails": "Get ready for an unforgettable night as the singing sensation, takes the stage for the first time in Vizag! Known for his soulful voice and chart-topping hits, Armaan is set to mesmerize the audience with a spectacular live performance. From romantic ballads to foot-tapping numbers, experience the magic of his music up close and personal. Join us for a musical journey filled with emotions, energy, and excitement. Whether you're a die-hard fan or just love great music, this concert is not to be missed. Let's create memories that will last a lifetime!",
  "address": " Radisson Blu, Dwarka",
  "startDate": '2024-10-22T14:00:00',
  "endDate": '2024-11-24T10:00:00',
  "price": 500,
  "eventHost": "IMA Medical Organisation",
  "eventCapacity": 200,
  "eventLink": "https://youtu.be/kusi4vzL5Po?feature=shared"
}
