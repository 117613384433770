import React, { useState, useContext, useEffect } from 'react'
import Navigation from "../Navigation/Navigation";
import YLoader from "../components/Loader";
import './homescreen.css';
import { Avatar, IconButton, Typography } from '@mui/material';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { IoBriefcaseOutline } from "react-icons/io5";
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import { SiYoutubeshorts } from "react-icons/si";
import SettingsIcon from '@mui/icons-material/Settings';
import { LiaStoreAltSolid } from "react-icons/lia";
import { FaRegPenToSquare } from "react-icons/fa6";
import { LuClipboardSignature } from "react-icons/lu";
import { TbStethoscope } from "react-icons/tb";
import { IoIosPeople } from "react-icons/io";
import { FaCartFlatbedSuitcase } from "react-icons/fa6";
import { PiTrolleySuitcase } from "react-icons/pi";
import { GiAutoRepair } from "react-icons/gi";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { RiUserShared2Line } from "react-icons/ri";
import { YContext } from "../Context/YContext";
import { useHistory } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Constants from "../MeddyConnectConstant";

const HomeScreen = () => {
    const theme = useTheme();
    const history = useHistory();
    const { currentUser } = useContext(YContext);
    const [loading, setLoading] = useState(true);
    const [sections, setSections] = useState([]);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (currentUser) {
            // Get the sections based on the currentUser userType once available
            const userSections = getSectionsByUserType();
            setSections(userSections);
            setLoading(false); // Set loading to false once sections are ready
        }
    }, [currentUser, isMobile]);

    const getSectionsByUserType = () => {
        switch (currentUser.userType) {
            case 'DOCTOR':
                return [
                    {
                        title: 'Store',
                        cards: [
                            ...(
                                isMobile
                                    ? [
                                        {
                                            icon: FaCartFlatbedSuitcase,
                                            title: 'Buy Medical Instrument',
                                            path: Constants.PRODUCT_CATEGORY,
                                            data: { title: 'Buy Instrument' },
                                        }
                                    ]
                                    : [
                                        {
                                            icon: FaCartFlatbedSuitcase,
                                            title: 'Buy New Medical Instrument',
                                            path: Constants.PRODUCT_CATEGORY,
                                            data: { title: 'Buy New Instrument' },
                                        },
                                        {
                                            icon: PiTrolleySuitcase,
                                            title: 'Buy Used Medical Instrument',
                                            path: Constants.PRODUCT_CATEGORY,
                                            data: { title: 'Buy Used Instrument' },
                                        },
                                    ]
                            ),
                            {
                                icon: GiAutoRepair,
                                title: 'Repair & Contract',
                                path: Constants.REPAIR_AND_CONTRACT,
                            },
                            {
                                icon: MdOutlineCurrencyRupee,
                                title: 'Sell Medical Instrument',
                                path: Constants.SELL_PRODUCT,
                            },
                            {
                                icon: LiaStoreAltSolid,
                                title: 'Brand Store',
                                path: Constants.NEW_PRODUCT_BRAND_CATALOGUE,
                            },
                            {
                                icon: SettingsIcon,
                                title: 'Manage Products',
                                path: Constants.INVENTORY,
                            },
                        ],
                    },
                    {
                        title: 'Job',
                        cards: [
                            {
                                icon: FaRegPenToSquare,
                                title: 'Apply For Job (Employee)',
                                path: Constants.ALL_JOB_APPLICATIONS,
                            },
                            {
                                icon: LuClipboardSignature,
                                title: 'My Job Applications',
                                path: Constants.MY_JOB_APPLICATIONS,
                            },
                            {
                                icon: IoBriefcaseOutline,
                                title: 'Post a Job (Employer)',
                                path: Constants.POST_A_JOB,
                            },
                            {
                                icon: SettingsIcon,
                                title: 'Manage Job',
                                path: Constants.MANAGE_JOB_APPLICATIONS,
                            },
                        ],
                    },
                    {
                        title: 'Observership',
                        cards: [
                            {
                                icon: IoBriefcaseOutline,
                                title: 'Post Observership',
                                path: Constants.POST_AN_OBSERVERSHIP,
                            },
                            {
                                icon: SettingsIcon,
                                title: 'Manage Observership',
                                path: Constants.MANAGE_OBSERVERSHIP_APPLICATIONS,
                            },
                        ],
                    },
                    {
                        title: 'Referral',
                        cards: [
                            {
                                icon: TbStethoscope,
                                title: 'Refer a Patient',
                                path: Constants.REFER_A_PATIENT,
                            },
                            {
                                icon: IoIosPeople,
                                title: 'Referrals',
                                path: Constants.REFERRED_TO_ME,
                            },
                            {
                                icon: RiUserShared2Line,
                                title: 'Refer By Me',
                                path: Constants.REFERRED_BY_ME,
                            },
                        ],
                    },
                ];

            case 'HOSPITAL':
                return [
                    {
                        title: 'Store',
                        cards: [
                            ...(
                                isMobile
                                    ? [
                                        {
                                            icon: FaCartFlatbedSuitcase,
                                            title: 'Buy Medical Instrument',
                                            path: Constants.PRODUCT_CATEGORY,
                                            data: { title: 'Buy Instrument' },
                                        }
                                    ]
                                    : [
                                        {
                                            icon: FaCartFlatbedSuitcase,
                                            title: 'Buy New Medical Instrument',
                                            path: Constants.PRODUCT_CATEGORY,
                                            data: { title: 'Buy New Instrument' },
                                        },
                                        {
                                            icon: PiTrolleySuitcase,
                                            title: 'Buy Used Medical Instrument',
                                            path: Constants.PRODUCT_CATEGORY,
                                            data: { title: 'Buy Used Instrument' },
                                        },
                                    ]
                            ),
                            {
                                icon: GiAutoRepair,
                                title: 'Repair & Contract',
                                path: Constants.REPAIR_AND_CONTRACT,
                            },
                            {
                                icon: MdOutlineCurrencyRupee,
                                title: 'Sell Medical Instrument',
                                path: Constants.SELL_PRODUCT,
                            },
                            {
                                icon: LiaStoreAltSolid,
                                title: 'Brand Store',
                                path: Constants.NEW_PRODUCT_BRAND_CATALOGUE,
                            },
                            {
                                icon: SettingsIcon,
                                title: 'Manage Products',
                                path: Constants.INVENTORY,
                            },
                        ],
                    },
                    {
                        title: 'Job',
                        cards: [
                            {
                                icon: IoBriefcaseOutline,
                                title: 'Post a Job (Employer)',
                                path: Constants.POST_A_JOB,
                            },
                            {
                                icon: SettingsIcon,
                                title: 'Manage Job',
                                path: Constants.MANAGE_JOB_APPLICATIONS,
                            },
                        ],
                    },
                    {
                        title: 'Observership',
                        cards: [
                            {
                                icon: IoBriefcaseOutline,
                                title: 'Post Observership',
                                path: Constants.POST_AN_OBSERVERSHIP,
                            },
                            {
                                icon: SettingsIcon,
                                title: 'Manage Observership',
                                path: Constants.MANAGE_OBSERVERSHIP_APPLICATIONS,
                            },
                        ],
                    },
                ];

            case 'VENDOR':
                if (currentUser.vendorType === "MANUFACTURER") {
                    return [
                        {
                            title: 'Store',
                            cards: [
                                {
                                    icon: MdOutlineCurrencyRupee,
                                    title: 'Sell Medical Instrument',
                                    path: Constants.SELL_PRODUCT,
                                },
                                {
                                    icon: EngineeringOutlinedIcon,
                                    title: 'Repair & Sell Contracts',
                                    path: Constants.REPAIR_AND_SELL_CONTRACTS,
                                },
                                {
                                    icon: FiberNewIcon,
                                    title: 'Showcase Your Product',
                                    path: Constants.SHOW_CASE_YOUR_PRODUCT,
                                },
                                {
                                    icon: SettingsIcon,
                                    title: 'Manage Products',
                                    path: Constants.INVENTORY,
                                },
                            ],
                        },
                    ];
                } else if (currentUser.vendorType === "DISTRIBUTOR") {
                    return [
                        {
                            title: 'Store',
                            cards: [
                                {
                                    icon: MdOutlineCurrencyRupee,
                                    title: 'Sell Medical Instrument',
                                    path: Constants.SELL_PRODUCT,
                                },
                                {
                                    icon: EngineeringOutlinedIcon,
                                    title: 'Repair & Sell Contracts',
                                    path: Constants.REPAIR_AND_SELL_CONTRACTS,
                                },
                                {
                                    icon: LiaStoreAltSolid,
                                    title: 'Brand Store',
                                    path: Constants.NEW_PRODUCT_BRAND_CATALOGUE,
                                },
                                {
                                    icon: SettingsIcon,
                                    title: 'Manage Products',
                                    path: Constants.INVENTORY,
                                },
                            ],
                        },
                    ];
                }
                return [];

            case 'STUDENT':
                return [
                    {
                        title: 'Observership',
                        cards: [
                            {
                                icon: FaRegPenToSquare,
                                title: 'Apply for Observership',
                                path: Constants.ALL_OBSERVERSHIP_APPLICATIONS,
                            },
                            {
                                icon: LuClipboardSignature,
                                title: 'My Observership Applications',
                                path: Constants.MY_OBSERVERSHIP_APPLICATIONS,
                            },
                        ],
                    },
                ];

            case 'STAFF':
                return [
                    {
                        title: 'Job',
                        cards: [
                            {
                                icon: FaRegPenToSquare,
                                title: 'Apply For Job (Employee)',
                                path: Constants.ALL_JOB_APPLICATIONS,
                            },
                            {
                                icon: LuClipboardSignature,
                                title: 'My Job Applications',
                                path: Constants.MY_JOB_APPLICATIONS,
                            },
                        ],
                    },
                ];

            case 'MEDICAL_ASSOCIATION':
                return [
                    // {
                    //     title: 'Observership',
                    //     cards: [
                    //         {
                    //             icon: WorkOutlineIcon,
                    //             title: 'Post Observership',
                    //             path: Constants.POST_AN_OBSERVERSHIP,
                    //         },
                    //         {
                    //             icon: EditNoteIcon,
                    //             title: 'Manage Observerships',
                    //             path: Constants.MANAGE_OBSERVERSHIPS,
                    //         },
                    //     ],
                    // },
                ];

            default:
                return [];
        }
    };

    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="left-Drawer">
                        <Navigation />
                    </div>

                    <div className="homeScreen-Container">

                        {sections.map((section, index) => (
                            <div className="main-Div" key={index}>
                                <div className='header-Div'>
                                    <Typography align="left" className='main_title'>
                                        {section.title}
                                    </Typography>
                                </div>
                                <div className='grid-container' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                    {section.cards.map((card, cardIndex) => (
                                        <div className="card-box" key={cardIndex}>
                                            <Avatar className='Avatar-style'>
                                                <IconButton
                                                    onClick={() =>
                                                        card.title === "Buy Medical Instrument" ||
                                                            card.title === "Buy New Medical Instrument" ||
                                                            card.title === "Buy Used Medical Instrument"
                                                            ? history.push(card.path, card.data)
                                                            : history.push(card.path)
                                                    }
                                                >
                                                    <card.icon className='icon-style' />
                                                </IconButton>
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                {card.title}
                                            </Typography>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}

                    </div>
                </div>

                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default HomeScreen