
const Urls = {
    // Signup & Login
    GENERATE_OTP_URL :  "verification/mobile/generate-otp",
    VALIDATE_OTP_URL : "verification/mobile/validate-otp",
    RESET_PASSWORD_URL : "login/reset",
    USER_INFO_URL : "login/info",
    USER_EXISTS_URL : "login/exists",
    UPDATE_PASSWORD_URL : "login/update-password",
    GENERATE_TOKEN_URL : "token/generate-token",
    CREATE_DOCTOR_URL : "doctor",
    CREATE_HOSPITAL_URL : "hospital",
    CREATE_VENDOR_URL : "vendor",
    CREATE_STUDENT_URL : "student",
    CREATE_STAFF_URL : "staff",
    CREATE_ASSOCIATION_URL : "medical-association",

    REFERENCES_PROFESSIONS_URL : "references/professions",
    REFERENCES_COUNTRIES_URL : "references/countries",

    // Marketplace
    PRODUCT_CATEGORIES_URL : "references/item-categories",
    GET_SELLER_CONTACT_DETAILS_URL : "items/get-contact",
    UPLOAD_ITEM_IMAGES_URL : "items/upload-image",
    UPLOAD_ITEM_ATTACHMENTS_URL : "items/upload-attachment",
    GET_INTERESTED_USER_CALL_DETAILS_URL : "items/get-contact",

    // Verification
    VERIFICATION_DOCUMENT_UPLOAD_URL : "identities",

    // Referral
    PATIENT_REFERRALS_URL : "patient-referrals",
    UPLOAD_REFERRAL_DOCUMENT_URL : "patient-referrals/upload-attachment",

    // View other user profile
    USER_PROFILE_URL : "users/info",

    // Job and Observership
    JOBS_URL : "jobs",
    OBSERVERSHIP_URL : "internships",
    USER_JOB_APPLICATIONS_URL : "jobs/user-applications",
    USER_OBSERVERSHIP_APPLICATIONS_URL : "internships/user-applications",
    GET_POSTED_JOBS_BY_USER_URL : "jobs/findActiveByUser",
    GET_POSTED_OBSERVERSHIPS_BY_USER_URL :"internships/findActiveByUser",
    GET_APPLICANTS_FOR_JOBS_URL : "jobs/applications",
    GET_APPLICANTS_FOR_OBSERVERSHIP_URL : "internships/applications",
    GET_JOB_APPLICANT_CONTACT_DETAILS_URL : "jobs/get-contact",
    GET_OBSERVERSHIP_APPLICANT_CONTACT_DETAILS_URL : "internships/get-contact",

    // Notifications
    GET_NOTIFICATION_URL : "notifications/findActiveByUser",

    // Logged-In User Profile
    DELETE_PROFILE_PHOTO_URL : "profiles",
    UPLOAD_PROFILE_PHOTOS_URL : "profiles/upload-image",

    // Deactivate Account
    DEACTIVATE_ACCOUNT_URL : "admin/deactivate",

    // Shorts
    CREATE_SHORTS_URL : "shorts",
    GET_ALL_SHORTS_URL : "shorts/findActiveByUser",
    UPDATE_LIKE_STATUS_URL : "shorts/like-unlike",
    GET_COMMENTS_FOR_SHORTS_URL : "shorts/messages",
    ADD_COMMENT_URL : "shorts/post-message",
    GET_SINGLE_SHORT_URL : "shorts/1",
    FIREBASE_AUTH_TOKEN_URL : "shorts/token"

    
}

export default Urls